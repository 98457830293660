<template>
  <div class="test-timer ml-10" v-if="prettyTime">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="10.5" stroke="#E55151" stroke-width="3"/>
      <path d="M8.94709 4.70444C7.92975 4.97704 7.0021 5.51262 6.25736 6.25736C5.51262 7.0021 4.97704 7.92975 4.70445 8.94709" stroke="#E55151" stroke-width="3" stroke-linecap="round"/>
      <path d="M27.0529 4.70444C28.0702 4.97704 28.9979 5.51262 29.7426 6.25736C30.4874 7.0021 31.023 7.92975 31.2956 8.94709" stroke="#E55151" stroke-width="3" stroke-linecap="round"/>
      <path d="M18 12V17.75C18 17.8881 18.1119 18 18.25 18H22.5" stroke="#E55151" stroke-width="3" stroke-linecap="round"/>
    </svg>
    <div class="test-timer__clock ml-5">{{ prettyTime }}</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "../../main";

function pad(n) {
  return n < 10 ? '0' + n : n
}
export default {
  name: "TestTimer",
  props: {
    tillTime: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      timer: null,
      prettyTime: null
    }
  },
  mounted() {
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()
  },
  computed: {
    ...mapGetters(['test', 'element'])
  },
  methods: {
    ...mapActions(['getTest', 'getQuestions', 'getElement', 'getModule', 'updCourse']),
    startTimer() {
      this.timer = setInterval(() => this.processInterval(), 500)
    },
    stopTimer() {
      if (!this.timer) {
        return;
      }

      clearTimeout(this.timer);
    },
    processInterval() {
      const now = new Date().getTime();
      const toDate = new Date(this.tillTime);

      const distance = toDate - now;

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) + hours * 60;
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (0 > minutes) {
        this.prettyTime = '00:00';
        this.stopTimer();
        this.$emit('ended');
        return;
      }

      this.prettyTime = `${pad(minutes)}:${pad(seconds)}`
    }
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        eventBus.$emit('timer:over')
        this.stopTimer()
      }
    }
  },
}
</script>

<style lang="scss">
.test-timer {
  display: flex;
  align-items: center;
  &__clock {
    font-size: 36px;
    line-height: 1;
    font-weight: 700;
  }
}
</style>
