<template>
  <div class="d-flex align-items-center">
    <course-institute :course="course"/>
    <course-date :course="course"/>
    <course-enroll-count :course="course"/>
  </div>
</template>

<script>
import courseMixin from "../../mixin/courseMixin";
import CourseInstitute from "./CourseInstitute.vue";
import CourseDate from "./CourseDate.vue";
import CourseEnrollCount from "./CourseEnrollCount.vue";


export default {
  name: "CourseTopHeader",
  components: {CourseEnrollCount, CourseDate, CourseInstitute},
  mixins: [courseMixin]
}
</script>

<style scoped>

</style>