<template>
  <div>
    <div class="social-enter">
      <a href="#" class="social-enter__button" @click="connectSocial('vk')">
          <span class="social-enter__icon social-enter__icon-vk">
            <img src="../assets/icons/vk.png" />
          </span>
        Войти с помощью Вконтакте
      </a>
    </div>
    <div class="social-enter d-flex justify-space-between align-center">
      <a href="#" class="social-enter__button" @click="connectSocial('yandex')">
          <span class="social-enter__icon social-enter__icon-ya">
            <img src="../assets/icons/ya.png" />
          </span>
      </a>
      <a href="#" class="social-enter__button" @click="connectSocial('ok')">
          <span class="social-enter__icon">
            <img src="../assets/icons/ok.png" />
          </span>
      </a>
      <a href="#" class="social-enter__button" @click="connectSocial('google')">
          <span class="social-enter__icon">
            <img src="../assets/icons/google.png" />
          </span>
      </a>
    </div>
    <div class="d-flex align-center mt-16">
      <div class="form-separator mr-7"></div>
      или
      <div class="form-separator ml-7"></div>
    </div>
  </div>
</template>

<script>
import {AppConfig, SocialNetwork} from "../../../src/global";
export default {
  name: "SocialEnter",
  data() {
    return {
      Networks: SocialNetwork
    }
  },
  methods: {
    connectSocial(network) {
      const redirectUri = `${location.protocol}//${location.host}/login/connect/${network}`;
      switch (network) {
        case  SocialNetwork.yandex:
          window.location = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${AppConfig.yandexAppId}&redirect_uri=${redirectUri}`;
          break;
        case  SocialNetwork.facebook:
          window.location = `https://www.facebook.com/v6.0/dialog/oauth?client_id=${AppConfig.facebookAppId}&redirect_uri=${redirectUri}&scope=email%2Cpublic_profile`;
          break;
        case  SocialNetwork.vk:
          window.location = `https://oauth.vk.com/authorize?client_id=${AppConfig.vkClientId}&redirect_uri=${redirectUri}&response_type=code&scope=email`;
          break;
        case  SocialNetwork.ok:
          window.location = `https://connect.ok.ru/oauth/authorize?client_id=${AppConfig.okClientId}&scope=GET_EMAIL&response_type=code&redirect_uri=${redirectUri}`;
          break;
        case  SocialNetwork.google:
          window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${AppConfig.googleAppId}&response_type=code&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email`;
          break;
      }
      return false;
    },
  }
}
</script>

<style scoped>

</style>