<template>
  <router-link tag="div" :to="'/course/' + course.id" class="fill fill-sm fill-grey w-100">
    <course-institute :course="course" />
    <h5 class="text-rothek font-24 mt-20 line-28">{{ course.name | truncate(50) }}</h5>
    <div class="font-18 line-22 mt-14"
         v-if="course.shortDescription"
         v-html="truncate(clearStyle(course.shortDescription), 100)"
    >
    </div>
  </router-link>
</template>

<script>
import CourseInstitute from "./CourseInstitute.vue";
import typografMixin from "../../mixin/typografMixin";

export default {
  name: "SameCourseCard",
  components: {CourseInstitute},
  mixins: [typografMixin],
  props: {
    course: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>