<template>
  <div>
    <test-question-name :current-key="currentKey" :question="question" />
    <div class="test__item-question-item form-radio radio" v-for="(answer) in question.answers" :key="answer.id" :class="{correct: answer.correct && answer.correct === true, incorrect: answer.correct === false}">
      <input type="radio" :name="question.question.id" :value="answer.id" :id="'simple_' + answer.id" v-model="model" />
      <label :for="'simple_' + answer.id" v-html="answer.name" class="ml-20 font-18 mt-20"></label>
    </div>
  </div>
</template>

<script>
import vmMixin from "../../mixin/vmMixin";
import TestQuestionName from "./TestQuestionName.vue";
import testQuestionMixin from "../../mixin/testQuestionMixin";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "TestQuestionSimple",
  components: {TestQuestionName},
  mixins: [vmMixin, testQuestionMixin],
  mounted() {
    this.model = this.question.selected ? this.question.selected[0] : null
  },
  watch: {
    model(val) {
      let questions = Object.assign([], this.questions)
      questions.questions[this.currentKey].selected = val
      this.setQuestions(questions)
    }
  },
  methods: {
    ...mapMutations(['setQuestions'])
  },
  computed: {
    ...mapGetters(['questions'])
  }
}
</script>

<style scoped>

</style>