<template>
  <div>
    <el-collapse v-model="activeModule" ref="moduleMenu" class="mt-40 module-list" accordion>
      <el-collapse-item v-for="module in course.modules" :key="module.id" :name="module.position" class="mb-16" :id="'module' + module.position">
        <template slot="title">
          <div class="module-list__title">
            <div class="d-flex align-items-center font-16 mb-10">
                <img width="20" src="../../assets/icons/calendar.svg" /> <div class="ml-10">{{ module.range[0] | formatDate }} - {{ module.range[1] | formatDate }}</div>
            </div>
            Модуль {{ module.position }}. {{ module.name }}
          </div>
        </template>
        <div class="pl-24 pr-24">
          <template v-if="!course.enroll">
            <hr class="mt-0"/>
            <span v-html="module.description"></span>
          </template>
          <template v-else>
            <module-element-list :module="module" />
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModuleElementList from "./ModuleElementList.vue";

export default {
  name: "ModuleList",
  components: {ModuleElementList},
  computed: {
    ...mapGetters(['course', 'module'])
  },
  methods: {
    ...mapActions(['getModule', 'getElement'])
  },
  props: {
    isEdu: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      activeModule: null
    }
  },
  mounted() {
    if(this.module) {
      this.activeModule = this.module.position
    } else {
      this.activeModule = 1
    }
  },
  watch: {
    activeModule(val, oldVal) {
     if(oldVal && val && this.isEdu) {
        this.$router.push('/course/' + this.$route.params.id + '/module/' + val + '/element/1').catch(() => {})
      }
    },
    '$route.params.mIndex'(val) {
      this.activeModule = parseInt(val)
    }
  }
}
</script>