<template>
  <vue-pdf-app theme="light" :download="false" style="height: 100vh;" :pdf="src"></vue-pdf-app>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import "vue-pdf-app/dist/icons/pdf.ttf";
import "vue-pdf-app/dist/icons/pdf.woff";
import "vue-pdf-app/dist/icons/pdf.woff2";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "BasePdfReader",
  components: { VuePdfApp },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  mounted() {
    if(this.element.elementResult.status !== 'success') {
      this.axios.put(`element_results/${this.element.elementResult.id}`, {status: 'success', hideLoader: true}).then(async () => {
        await this.updCourse(this.$route.params.id)
        await this.getModule(this.$route.params.mIndex)
        await this.getElement(this.$route.params.eIndex)
      });
    }
  },
  methods: {
    ...mapActions(['getTest', 'getQuestions', 'getElement', 'getModule', 'updCourse']),
  },
  computed: {
    ...mapGetters(['element'])
  },
}
</script>

<style lang="scss">
#download, #scaleSelectContainer, #openFile, #viewBookmark, #print, #secondaryToolbarToggle {
  display: none!important;
}
</style>