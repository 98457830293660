<template>
  <el-row :class="styleClass">
    <el-col :lg="24" :md="24" :sm="24" :xs="24">
      <div class="fill fill-grey skeleton" :class="lineHeight">
        <br v-for="index in lines" :key="index" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Skeleton",
  props: {
    sizes: {
     type: Object,
      default() {
       return {
         lg: 24,
         md: 24,
         sm: 24,
         xs: 24
       }
      }
    },
    lines: {
      type: Number,
      default() {
        return 10
      }
    },
    styleClass: {
      type: String,
      default() {
        return 'mt-10'
      }
    },
    lineHeight: {
      type: String,
      default() {
        return 'p-40 pb-60'
      }
    }
  }
}
</script>

<style scoped>

</style>