<template>
  <div>
    <svg v-if="element.elementResult && element.elementResult.status === 'success'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="14" height="14" rx="1.82594" fill="#6EB253" stroke="#6EB253" stroke-width="2"/>
      <path d="M4 8.22964L6.01853 10.2482L11.2667 5" stroke="#1F1F1F" stroke-width="2" stroke-linecap="round"/>
    </svg>
    <div v-else-if="locked">
      <img width="15" src="../../assets/icons/locked.svg" />
    </div>
  </div>
</template>

<script>
import elementMixin from "../../mixin/elementMixin";

export default {
  name: "ElementStatus",
  mixins: [elementMixin],
  props: {
    locked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>