import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
import routes from "./routes";
import VueAuth from '@websanova/vue-auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueKinesis from 'vue-kinesis'
import checkView from 'vue-check-view'
import store from "./store";
import moment from "moment";
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import "vue-pdf-app/dist/icons/main.css";

import 'vue-toastr/src/vue-toastr.scss';
import Toast from 'vue-toastr';

Vue.use(Toast);

Vue.use(VueRouter)
Vue.use(VueVideoPlayer)
Vue.use(VueMeta)
Vue.use(VueKinesis)
Vue.use(ElementUI)

Vue.router = routes
Vue.config.productionTip = false;

Vue.use(checkView)
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.config.productionTip = false

const errorHandler = (error) => {
  return error.response
};

const successHandler = (response) => {
  return response
};

Vue.axios.interceptors.request.use((config) => {

  if(config.url === 'token/refresh') {
    config.data = {...config.data, refresh_token: localStorage.getItem('refresh_token')}
  }

  if(config.params && config.params.hideLoader) {
    return config;
  }

  if(config.data && config.data.hideLoader) {
    return config;
  }

  return config;
});
Vue.axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

Vue.use(VueAuth, {
  auth: require('./auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  fetchData: {
    url: 'me',
    success: function (res) {
      if(res.status === 200) {
        Vue.auth.user(res.data);
      }
    }
  },
  tokenStore: ['localStorage'],
  roleKey: 'roles',
  refreshTokenName: 'refresh_token',
  refreshData: {url: 'token/refresh', method: 'POST', interval: 25},
});

Vue.filter('truncate',  (text, stop, clamp) => {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('pluralize', function(number, locale, words){
  if (number % 10 === 1 && number % 100 !== 11) {
    return words[0];
  }
  if ((number % 10 >= 2 && number % 10 <= 4 && number % 100 < 10) || number % 100 >= 20) {
    return words[1];
  }

  return words[2];
})

Vue.filter('formatDate', (value, format = 'DD.MM.YYYY HH:mm') => {

  moment.locale('ru')
  const number = moment(String(value)).format('M');
  let monthName = null
  switch (number) {
    case '1':
      monthName = 'января'
      break;
    case '2':
      monthName = 'февраля'
      break;
    case '3':
      monthName = 'марта'
      break;
    case '4':
      monthName = 'апреля'
      break;
    case '5':
      monthName = 'мая'
      break;
    case '6':
      monthName = 'июня'
      break;
    case '7':
      monthName =  'июля'
      break;
    case '8':
      monthName = 'августа'
      break;
    case '9':
      monthName = 'сентября'
      break;
    case '10':
      monthName = 'октября'
      break;
    case '11':
      monthName = 'ноября'
      break;
    case '12':
      monthName = 'декабря'
      break;
  }

  if('M' === format) {
    return monthName;
  }

  if('full' === format) {
    return moment(String(value)).utc().format('DD') + ' ' + monthName + ' ' + moment(String(value)).utc().format('YYYY')
        + ' в ' +  moment(String(value)).utc().format('HH:mm')
  }

  if('full_date_only' === format) {
    return moment(String(value)).utc().format('DD') + ' ' + monthName + ' ' + moment(String(value)).utc().format('YYYY')
  }

  if (value) {
    return moment(String(value)).utc().format(format)
  }
})

export const eventBus = new Vue(); // creating an event bus.

new Vue({
  render: h => h(App),
  router: routes,
  store
}).$mount('#app')
