<template>
  <div class="menu">
    <nav class="menu__item d-flex align-center flex-direction-row">
      <a href="/catalog" class="menu__item__blue">Образование</a>
      <a target="_blank" href="https://universarium-school.ru" class="d-flex align-center flex-direction-row">
        <span class="mr-10">Детям</span>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="15" fill="#6EB253"/>
          <path d="M20.4672 11.119C20.4672 10.7048 20.1314 10.369 19.7172 10.369L12.9672 10.369C12.5529 10.369 12.2172 10.7048 12.2172 11.119C12.2172 11.5332 12.5529 11.869 12.9672 11.869L18.9672 11.869L18.9672 17.869C18.9672 18.2832 19.3029 18.619 19.7172 18.619C20.1314 18.619 20.4672 18.2832 20.4672 17.869L20.4672 11.119ZM10.8194 21.0774L20.2475 11.6494L19.1868 10.5887L9.75873 20.0168L10.8194 21.0774Z" fill="#1F1F1F"/>
        </svg>
      </a>
      <a target="_blank" href="https://edu-oko.ru/?id=254" class="d-flex align-center flex-direction-row">
        <span class="mr-10">СДПК</span>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="15" fill="#F7941E"/>
          <path d="M20.4672 11.119C20.4672 10.7048 20.1314 10.369 19.7172 10.369L12.9672 10.369C12.5529 10.369 12.2172 10.7048 12.2172 11.119C12.2172 11.5332 12.5529 11.869 12.9672 11.869L18.9672 11.869L18.9672 17.869C18.9672 18.2832 19.3029 18.619 19.7172 18.619C20.1314 18.619 20.4672 18.2832 20.4672 17.869L20.4672 11.119ZM10.8194 21.0774L20.2475 11.6494L19.1868 10.5887L9.75873 20.0168L10.8194 21.0774Z" fill="#1F1F1F"/>
        </svg>
      </a>
    </nav>
    <nav class="menu__item d-flex align-center">
      <router-link to="/catalog">Каталог курсов</router-link>
      <router-link active-class="active" to="/partners">Партнёрам</router-link>
      <router-link active-class="active" to="/about">О проекте</router-link>
    </nav>
    <login-dialog v-model="showDialog" />
    <nav class="menu__item d-flex align-center">
      <a v-if="!$auth.user().id" @click.prevent="login()" href="#">Войти</a>
      <template v-else>
        <a class="mr-8" @click.prevent="$router.push('/me')" href="#">
          <svg class="non-transform" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="46" height="46" rx="23" fill="#F5F5F5"/>
            <path d="M30.6634 30.6667V28.7501C30.6634 27.7334 30.2595 26.7584 29.5407 26.0395C28.8218 25.3206 27.8467 24.9167 26.8301 24.9167H19.1634C18.1467 24.9167 17.1717 25.3206 16.4528 26.0395C15.7339 26.7584 15.3301 27.7334 15.3301 28.7501V30.6667" stroke="#1F1F1F" stroke-width="1.94595" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.9974 21.0834C25.1145 21.0834 26.8307 19.3672 26.8307 17.2501C26.8307 15.133 25.1145 13.4167 22.9974 13.4167C20.8803 13.4167 19.1641 15.133 19.1641 17.2501C19.1641 19.3672 20.8803 21.0834 22.9974 21.0834Z" stroke="#1F1F1F" stroke-width="1.94595" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <a @click.prevent="$auth.logout()" href="#">
          <svg class="non-transform" width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.125 18.2084V27.7917C31.125 30.4339 28.9755 32.5834 26.3333 32.5834H22.5C21.971 32.5834 21.5417 32.155 21.5417 31.6251C21.5417 31.0951 21.971 30.6667 22.5 30.6667H26.3333C27.9184 30.6667 29.2083 29.3768 29.2083 27.7917V18.2084C29.2083 16.6233 27.9184 15.3334 26.3333 15.3334H22.5C21.971 15.3334 21.5417 14.905 21.5417 14.3751C21.5417 13.8451 21.971 13.4167 22.5 13.4167H26.3333C28.9755 13.4167 31.125 15.5663 31.125 18.2084ZM25.0942 22.3225L21.2609 18.4892C20.8862 18.1145 20.2805 18.1145 19.9058 18.4892C19.5311 18.8639 19.5311 19.4696 19.9058 19.8443L22.1032 22.0417H14.8333C14.3043 22.0417 13.875 22.4701 13.875 23.0001C13.875 23.53 14.3043 23.9584 14.8333 23.9584H22.1032L19.9058 26.1558C19.5311 26.5306 19.5311 27.1363 19.9058 27.511C20.0927 27.6979 20.338 27.7917 20.5833 27.7917C20.8287 27.7917 21.074 27.6979 21.2609 27.511L25.0942 23.6777C25.4689 23.3029 25.4689 22.6963 25.0942 22.3225Z" fill="#A5A5A5"/>
          </svg>
        </a>
      </template>
    </nav>
  </div>
</template>

<script>
import LoginDialog from "./LoginDialog.vue";
import {eventBus} from "../main";

export default {
  name: "MainMenu",
  components: {LoginDialog},
  data() {
    return {
      showDialog: false
    }
  },
  mounted() {
    eventBus.$on('show:login', () => {
      this.showDialog = true
    })
  },
  methods: {
    login() {
      this.showDialog = true
      return false;
    }
  }
}
</script>