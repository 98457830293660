<template>
  <div class="d-flex justify-start mt-8">
    <el-row :gutter="8" class="profile__stats">
      <el-col :lg="12" :md="6" :sm="6" :xs="6">
        <div class="profile__stats-item m-0" :class="{skeleton: !myStats.courses, 'bg-blue': myStats.courses}">
          <template v-if="myStats.courses">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.courses }}</h3>
            Курсов пройдено
          </template>
        </div>
      </el-col>
      <el-col :lg="12" :md="6" :sm="6" :xs="6">
        <div class="profile__stats-item m-0" :class="{skeleton: !myStats.courses, 'bg-purple': myStats.courses}">
          <template v-if="myStats.modules">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.modules }}</h3>
            Модулей пройдено
          </template>
        </div>
      </el-col>
      <el-col :lg="12" :md="6" :sm="6" :xs="6">
        <div class="profile__stats-item m-0" :class="{skeleton: !myStats.tests, 'bg-orange': myStats.tests}">
          <template v-if="myStats.tests">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.tests }}</h3>
            Тестов пройдено
          </template>
        </div>
      </el-col>
      <el-col :lg="12" :md="6" :sm="6" :xs="6">
        <div class="profile__stats-item m-0" :class="{skeleton: !myStats.videos, 'bg-green': myStats.videos}">
          <template v-if="myStats.videos">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.videos }}</h3>
            Видео просмотрено
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProfileStatsMobile",
  computed: mapGetters(['myStats'])
}
</script>

<style scoped>

</style>