<template>
  <div class="course-card-mini fill fill-grey fill fill-grey-sm p-20" @click="gotoCourse">
    <div>
      <div class="d-flex justify-space-between">
        <div>
          <span class="badge bg-dark color-white">
            <template v-if="course.institution">
              {{ course.institution.shortName | truncate(10, '...') }}
            </template>
            <template v-else>
              ОКО
            </template>
          </span>
        </div>
        <div>
          <span class="badge bg-white">
            {{ course.statusRu ? course.statusRu : 'СКОРО' }}
          </span>
        </div>
      </div>
      <div class="mt-20">
        <div class="font-20 line-23 text-rothek course-card-mini__name">
          {{ course.name }}
        </div>
      </div>
      <div class="mt-40 mb-20 color-grey">
        {{ course.tasks.videos }} видео, {{ course.tasks.tasks }} {{ course.tasks.tasks | pluralize('ru', ['задание', 'задания', 'заданий']) }}
      </div>
      <el-progress :stroke-width="8" :percentage="Math.round(course.enroll.progress)" color="#6EB253"></el-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseCardMini",
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  methods: {
    strippedContent(content) {
      let regex = /(<([^>]+)>)/ig;
      return content.replace(regex, "");
    },
    format(percentage) {
      return `${percentage}%`;
    },
    gotoCourse() {
      window.location.href = '/course/' + this.course.id
    }
  }
}
</script>
<style lang="scss">
.course-card-mini {
  cursor: pointer;
  &__name {
    height: 46px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>