<template>
  <span :class="'ml-' + margin">{{ element.name }}</span>
</template>

<script>
import elementMixin from "../../mixin/elementMixin";

export default {
  name: "ElementTitle",
  mixins: [elementMixin],
  props: {
    margin: {
      type: Number,
      default() {
        return 10
      }
    }
  }
}
</script>

<style scoped>

</style>