// @ts-nocheck
export default {
  data() {
    return {
      model: {}
    }
  },
  props: {
    value: {
      type: [Object, String, Array, Number]
    }
  },
  mounted() {
    this.model = this.value
  },
  watch: {
    model: {
      handler: async function(val) {
        this.$emit('input', val)
      },
      deep: true
    },
    value: {
      handler: async function(val) {
        this.model = val
      }
    }
  }
}
