<template>
  <div class="badge bg-dark color-white  mr-10">
    {{ startDate | formatDate('DD.MM.YYYY') }} - <template>{{ endDate | formatDate('DD.MM.YYYY') }}</template>
  </div>
</template>

<script>
import courseMixin from "../../mixin/courseMixin";

export default {
  name: "CourseDate",
  mixins: [courseMixin],
  computed: {
    startDate() {
      return this.course.modules[0].startDateTime
    },
    endDate() {
      return this.course.modules.slice(-1)[0].endDateTime
    }
  }
}
</script>

<style scoped>

</style>