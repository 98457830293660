<template>
  <div class="d-flex justify-end">
    <el-row :gutter="12" class="profile__stats">
      <el-col :lg="12" :md="6" :sm="6" :xs="6">
        <div class="profile__stats-item" :class="{skeleton: !myStats, 'bg-blue': myStats}">
          <template v-if="myStats">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.courses }}</h3>
            Курсов пройдено
          </template>
        </div>
        <div class="profile__stats-item" :class="{skeleton: !myStats, 'bg-purple': myStats}">
          <template v-if="myStats">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.modules }}</h3>
            Модулей пройдено
          </template>
        </div>
      </el-col>
      <el-col :lg="12" :md="6" :sm="6" :xs="6">
        <div class="profile__stats-item" :class="{skeleton: !myStats, 'bg-orange': myStats}">
          <template v-if="myStats">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.tests }}</h3>
            Тестов пройдено
          </template>
        </div>
        <div class="profile__stats-item" :class="{skeleton: !myStats, 'bg-green': myStats}">
          <template v-if="myStats">
            <h3 class="text-rothek font-25 line-30 mb-5">{{ myStats.videos }}</h3>
            Видео просмотрено
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileStats",
  computed: mapGetters(['myStats']),
  methods: mapActions(['getMyStats']),
  mounted() {
    this.getMyStats()
  }
}
</script>

<style scoped>

</style>