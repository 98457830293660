<template>
  <el-col class="mt-21" :lg="6" :md="6" :sm="24" :xs="24">
    <div class="fill fill-grey fill-sm skeleton">
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </div>
  </el-col>
</template>

<script>
export default {
  name: "ProfileCourseSkeleton"
}
</script>

<style scoped>

</style>