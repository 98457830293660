<template>
  <section class="category-list" v-if="model">
    <div class="fill fill-grey mt-24">
      <h1 class="text-rothek font-48 line-52 md-hidden">Что вы хотите <br/>изучить?</h1>
      <h2 class="text-rothek font-24 line-30 md-visible">Курсы<br/> Универсариума</h2>
      <div class="mt-40 mb-40 main-page__categories">
        <template v-if="allCategories.length">
          <button v-for="category in allCategories"
                  :key="category.id"
                  @click="toggleCategory(category.id)"
                  class="btn"
                  :class="{active: model.categories.indexOf(category.id) > -1}"
          >
            {{ category.title }}
          </button>
          <button  @click="clear" class="btn btn-border" v-if="model.categories.length">Показать все</button>
        </template>
        <template v-else>
          <button v-for="i in 30" :key="i" class="btn skeleton">Загрузка</button>
        </template>
      </div>
      <div class="search-input">
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12.4765" cy="13.3994" r="8.32224" transform="rotate(-45 12.4765 13.3994)" stroke="#A5A5A5"/>
          <path d="M18.6142 19.5371L25.1543 26.0772" stroke="#A9A9A9"/>
        </svg>
        <input @input="debounceSearch" placeholder="Поиск курса" type="search" name="search" />
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import vmMixin from "../../mixin/vmMixin";

export default {
  name: "CategoryList",
  data() {
    return {
      categories: [],
      model: null,
      debounce: null
    }
  },
  computed: {
    ...mapGetters(['allCategories'])
  },
  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if(event.target.value.length > 3 || event.target.value.trim().length === 0) {
          this.model.title = event.target.value
        }
      }, 600)
    },
    toggleCategory(category) {
      let index = this.model.categories.indexOf(category);
      if (index === -1) {
        this.model.categories.push(category);
      } else {
        this.model.categories.splice(index, 1);
      }
    },
    clear() {
      this.model.categories = []
    }
  },
  mixins: [vmMixin]
}
</script>

<style lang="scss">
.category-list {
  .btn {
    border: 2px solid #FFF;
    &:hover, &.active {
      border: 2px solid #00AEEF;
    }
  }
}
</style>