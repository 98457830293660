<template>
  <div class="fill fill-grey profile">
    <h2 class="text-rothek">
          <span>
            {{ this.$auth.user().lastName }}<br/>
            {{ this.$auth.user().firstName }} {{ this.$auth.user().middleName }}
          </span>
      <a href="/admin/profile?settings=true" target="_blank" class="profile__edit">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2047 20.7959L29.0007 18.9999C29.546 18.4547 29.8186 18.1821 29.9644 17.888C30.2416 17.3284 30.2416 16.6715 29.9644 16.1119C29.8186 15.8178 29.546 15.5452 29.0007 14.9999C28.4555 14.4547 28.1829 14.1821 27.8888 14.0363C27.3292 13.759 26.6723 13.759 26.1127 14.0363C25.8186 14.1821 25.546 14.4547 25.0007 14.9999L23.1821 16.8186C24.146 18.4692 25.5321 19.8448 27.2047 20.7959ZM21.7276 18.2731L14.8571 25.1436C14.4321 25.5686 14.2195 25.7812 14.0798 26.0422C13.9401 26.3033 13.8811 26.5981 13.7632 27.1875L13.1478 30.2645C13.0813 30.5971 13.0481 30.7634 13.1427 30.858C13.2373 30.9526 13.4036 30.9194 13.7362 30.8529L16.8132 30.2375C17.4026 30.1196 17.6973 30.0606 17.9584 29.9209C18.2195 29.7812 18.4321 29.5686 18.8571 29.1436L25.7465 22.2542C24.1249 21.2385 22.7532 19.8762 21.7276 18.2731Z" fill="#222222"/>
          <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#ededed"/>
        </svg>
      </a>
    </h2>
    <div class="color-grey mt-12">{{ this.$auth.user().username }}</div>
  </div>
</template>

<script>
export default {
  name: "ProfileUser"
}
</script>

<style scoped>

</style>