<template>
  <div class="tutor-avatar">
    <div v-if="photo && photo.thumbs" class="tutor-avatar__img" :style="{backgroundImage: 'url(' + photo.thumbs.big + ')'}"></div>
    <svg v-else width="106" height="130" viewBox="0 0 106 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="106" height="130" rx="16" fill="#ededed"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.8333 47.2906C38.8333 39.4665 45.1759 33.1239 53 33.1239C60.824 33.1239 67.1666 39.4665 67.1666 47.2906C67.1666 55.1146 60.824 61.4572 53 61.4572C45.1759 61.4572 38.8333 55.1146 38.8333 47.2906ZM38.8327 68.5409C29.0526 68.5409 21.1243 76.4692 21.1243 86.2492C21.1243 92.1173 25.8813 96.8742 31.7493 96.8742H74.2493C80.1174 96.8742 84.8743 92.1173 84.8743 86.2492C84.8743 76.4692 76.946 68.5409 67.166 68.5409H38.8327Z" fill="#D5D5D5"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "TutorAvatar",
  props: {
    photo: {
      type: Object,
      default() {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.tutor-avatar {
  &__img {
    width: 106px;
    height: 130px;
    background-size: cover;
    background-position: center center;
    border-radius: 16px;
  }
}
</style>