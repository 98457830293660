<template>
  <div class="fill fill-grey module" v-if="module">
    <div v-if="element" class="d-flex align-items-top mb-30 justify-content-between">
      <div class="d-flex">
        <element-type :element="element" />
        <h3 class="m-0 ml-10">
          Модуль {{ module.position }}. {{ module.name }}.<br/>
          <element-title :margin="0" :element="element" />
        </h3>
      </div>
      <test-timer v-if="element.type === 'list' && test && test.testInProcess && questions" :till-time="questions.endedAt * 1000" />
    </div>
    <skeleton v-else :lines="1" :style-class="'mb-20'" line-height="p-10 pb-20" />
    <el-row>
      <el-col :lg="8" :md="8" :sm="24" :xs="24">
        <div class="module__menu d-none d-lg-block" id="module__menu" :style="{height: calcHeight}">
          <module-list :is-edu="true" />
        </div>
        <module-pager />
      </el-col>
      <el-col :lg="16" :md="16" :sm="24" :xs="24">
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModuleList from "../components/module/ModuleList.vue";
import ElementType from "../components/module/ElementType.vue";
import ElementTitle from "../components/module/ElementTitle.vue";
import Skeleton from "../components/Skeleton.vue";
import ModulePager from "../components/module/ModulePager.vue";
import {eventBus} from "../main";
import TestTimer from "../components/test/TestTimer.vue";

export default {
  name: "ModulePage",
  components: {TestTimer, ModulePager, Skeleton, ElementTitle, ElementType, ModuleList},
  data() {
    return {
      calcHeight: 'auto'
    }
  },
  async mounted() {
    await this.getModule(this.$route.params.mIndex)
    if(this.module.isLocked) {
      await this.$router.push('/course/' + this.course.id + '/module/1/element/1').catch(() => {})
    }
    eventBus.$on('calcHeight', (calc) => {
      if(this.element.type === 'list') {
        this.calcHeight = (calc - 74) + 'px'
      } else {
        this.calcHeight = calc + 'px'
      }
    })
  },
  methods: {
    ...mapActions(['getModule', 'getElement'])
  },
  computed: {
    ...mapGetters(['course', 'module', 'element', 'questions', 'test'])
  },
  watch: {
    '$route.params.mIndex'(val, oldVal) {
      if(oldVal) {
        this.getModule(val)
        this.getElement(1)
      }
    }
  }
}
</script>

<style lang="scss">
.module {
  margin-top: 20px;
  .mb-16 {
    margin-bottom: 0!important;
  }
  &__menu {
    overflow-y: scroll;
    padding-right: 28px;
    .module-list {
      margin: 0!important;
      background: #fff;
      border-radius: 16px;
      .el-collapse-item__header {
        background: none;
      }
      .el-collapse-item:last-child {
        .el-collapse-item__header {
          border: none;
        }
      }
    }
  }
}
</style>