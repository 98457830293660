<template>
  <div class="d-flex align-items-center justify-content-center">
    <span v-if="show" class="mr-20 ml-20">
      <svg width="24" height="24" :stroke="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3" stroke-linecap="round"><animate attributeName="stroke-dasharray" dur="1.5s" calcMode="spline" values="0 150;42 150;42 150;42 150" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/><animate attributeName="stroke-dashoffset" dur="1.5s" calcMode="spline" values="0;-16;-59;-59" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/></circle><animateTransform attributeName="transform" type="rotate" dur="2s" values="0 12 12;360 12 12" repeatCount="indefinite"/></g></svg>
    </span>
    <span v-else>
      <slot>Записаться</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "SpinLoad",
  props: {
    color: {
      type: String,
      default() {
        return '#fff';
      }
    },
    show: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
}
</script>
<style scoped>
svg {
  height: 18px;
}
</style>