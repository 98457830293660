module.exports = {

  request: function (req, token) {
    this.options.http._setHeaders.call(this, req, {Authorization: 'Bearer ' + token});
  },

  response: function (res) {
    if(!res.data) {
      return;
    }
    let token = res.data.token;
    this.token('refresh_token', res.data.refresh_token);
    if (token) {
      return token;
    }
  }
};