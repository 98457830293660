<template>
  <div class="test-process">
    <test-question-list :current-key="key" />
    <test-menu v-model="key" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TestQuestionList from "./TestQuestionList.vue";
import TestMenu from "./TestMenu.vue";

export default {
  name: "TestProgress",
  components: {TestMenu, TestQuestionList},
  data() {
    return {
      key: 0
    }
  },
  computed: {
    ...mapGetters(['questions'])
  },
  watch: {
    key(key) {
      if((key + 1) > this.questions.questions.length) {
        this.key = 0
      } else if(key < 0) {
        this.key = this.questions.questions.length - 1
      }
    }
  }
}
</script>

<style scoped>

</style>