import axios from "axios";
export default {
    state: {
        categories: []
    },
    actions: {
        async getCategories(ctx) {
            let res = await axios.get('categories')
            ctx.commit('setCategories', res.data)
        }
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories
        }
    },
    getters: {
        allCategories(state) {
            return state.categories
        }
    }
}