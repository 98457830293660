<template>
  <div class="d-flex justify-content-end mt-26" v-if="questions.questions && questions.questions.length">
    <button class="mr-20 btn btn-sm btn-purple" @click="() => model--">Назад</button>
    <button v-if="questions.questions.length !== (model + 1)" class="btn btn-sm btn-black" @click="() => model++">Вперед</button>
    <button v-else class="btn btn-danger ml-15" @click="finish()">Завершить тест</button>
  </div>
</template>

<script>
import vmMixin from "../../mixin/vmMixin";
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "../../main";

export default {
  name: "TestMenu",
  mixins: [vmMixin],
  pops: {

  },
  computed: {
    ...mapGetters(['questions', 'test'])
  },
  mounted() {
    eventBus.$on('timer:over', () => {
      this.finish()
    })
  },
  methods: {
    ...mapActions(['getTest', 'getQuestions', 'getElement', 'getModule', 'updCourse']),
    finish() {
      this.axios.get(`tests/${this.test.id}/pass-process-finish`).then(async () => {
        await this.updCourse(this.$route.params.id)
        await this.getModule(this.$route.params.mIndex)
        await this.getElement(this.$route.params.eIndex)
        await this.getTest(this.test.id)
      })
    }
  }
}
</script>

<style scoped>

</style>