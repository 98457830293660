export default {
    props: {
        question: {
            type: Object
        },
        currentKey: {
            type: Number,
            required: true
        }
    }
}