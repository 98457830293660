<template>
  <div class="fill fill-grey mt-24" v-if="course.description">
    <h2 class="text-rothek font-36 mb-20 mt-20">О курсе</h2>
    <div v-html="clearStyle(course.description)"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import typografMixin from "../../mixin/typografMixin";

export default {
  name: "CourseDescription",
  computed: {
    ...mapGetters(['course'])
  },
  mixins: [typografMixin],
}
</script>

<style scoped>

</style>