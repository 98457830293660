<template>
  <div class="about-page">
    <kinesis-container event="scroll" class="container">
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-first">
          <img src="../assets/images/kinesis-first.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-second">
          <img src="../assets/images/kinesis-second.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-third">
          <img src="../assets/images/kinesis-third.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-fourth">
          <img src="../assets/images/kinesis-fourth.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-five">
          <img src="../assets/images/kinesis-five.svg" />
        </div>
      </kinesis-element>
      <div class="about-page__wrapper">
        <h1 class="text-rothek font-104 mb-60">О проекте</h1>
        <svg class="about-page__wrapper-icons mb-7" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23 45.5C35.4264 45.5 45.5 35.4264 45.5 23C45.5 10.5736 35.4264 0.5 23 0.5C10.5736 0.5 0.5 10.5736 0.5 23C0.5 35.4264 10.5736 45.5 23 45.5ZM21.2682 31.1402L33.7682 16.1402L32.2318 14.8598L20.4328 29.0186L13.7071 22.2929L12.2929 23.7071L19.7929 31.2071L20.5672 31.9814L21.2682 31.1402Z" fill="#F7941E"/>
        </svg>
        <h2 class="text-rothek font-36 mb-10">Миссия проекта</h2>
        <p class="font-20 line-27 font-16-md line-20-md mb-100 mb-50-md">Дать возможность миллионам людей в России получить качественное образования от лучших российских преподавателей и университетов.</p>
        <svg class="about-page__wrapper-icons mb-7" width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.7682 8.04966C18.3734 4.04794 19.176 2.04708 20.48 1.76978C20.8228 1.69686 21.1772 1.69686 21.52 1.76978C22.824 2.04708 23.6266 4.04794 25.2318 8.04966C26.1446 10.3254 26.601 11.4632 27.4551 12.2371C27.6946 12.4542 27.9546 12.6475 28.2315 12.8144C29.2186 13.4093 30.4508 13.5197 32.9153 13.7404C37.0872 14.114 39.1732 14.3009 39.8102 15.4902C39.9421 15.7365 40.0318 16.0032 40.0755 16.2792C40.2867 17.6118 38.7532 19.0069 35.6862 21.7972L34.8346 22.5721C33.4007 23.8766 32.6838 24.5289 32.2691 25.3429C32.0203 25.8312 31.8535 26.357 31.7754 26.8994C31.6451 27.8037 31.855 28.7499 32.2749 30.6424L32.4249 31.3185C33.1779 34.7123 33.5544 36.4093 33.0845 37.2433C32.6623 37.9926 31.8847 38.4722 31.0257 38.5132C30.0694 38.5589 28.7219 37.4609 26.027 35.2649C24.2514 33.8182 23.3637 33.0948 22.3782 32.8122C21.4775 32.5539 20.5225 32.5539 19.6219 32.8122C18.6363 33.0948 17.7486 33.8182 15.973 35.2649C13.2781 37.4609 11.9306 38.5589 10.9743 38.5132C10.1153 38.4722 9.33772 37.9926 8.91556 37.2433C8.44557 36.4093 8.82207 34.7123 9.57506 31.3185L9.72508 30.6424C10.145 28.7499 10.3549 27.8037 10.2246 26.8994C10.1465 26.357 9.97968 25.8312 9.73093 25.3429C9.31624 24.5289 8.59931 23.8766 7.16545 22.5721L6.31377 21.7972C3.24679 19.0069 1.71331 17.6118 1.92449 16.2792C1.96823 16.0032 2.05793 15.7365 2.18986 15.4902C2.82685 14.3009 4.91281 14.114 9.08471 13.7404C11.5492 13.5197 12.7814 13.4093 13.7685 12.8144C14.0454 12.6475 14.3054 12.4542 14.545 12.2371C15.399 11.4632 15.8554 10.3254 16.7682 8.04966Z" fill="#6EB253" stroke="#6EB253" stroke-width="2"/>
        </svg>
        <h2 class="text-rothek font-36 mb-40">Цель проекта</h2>
        <el-row :gutter="20" class="font-20 line-27 font-16-md line-20-md">
          <el-col :xs="24" :span="12" class="mb-8">
            <span class="text-rothek font-36 color-blue font-24-md line-25-md">Обеспечить</span> лидирующую позицию в электронном образовании российским университетам. Таким образом, мы сформируем и сохраним талантливые и заинтересованные кадры для российской экономики.
          </el-col>
          <el-col :xs="24" :span="12" class="mb-100 mb-60-md">
            <span class="text-rothek font-36 color-orange font-24-md line-25-md">Создать</span> платформу,<br/> где университеты России объединятся, чтобы предоставить энциклопедическую предпрофильную подготовку и целевое профильное обучение.
          </el-col>
        </el-row>
        <h2 class="text-rothek font-36 mb-40">Принципы реализации проекта</h2>
        <el-row :gutter="24" class="font-24 line-30 font-16-md line-20-md mb-60">
          <el-col :xs="24" :span="12" class="mb-24">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-purple">1</div>
              <p class="text-rothek">
                Открытая образовательная платформа и доступное обучение в рамках проекта
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :span="12" class="mb-24">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-green">2</div>
              <p class="text-rothek">
                Вовлечение в проект ведущих университетов и лучших преподавателей страны
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :span="12" class="mb-24">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-orange">3</div>
              <p class="text-rothek">
                Активная образовательная среда, использование современных технологий и методик
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :span="12" class="mb-24">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-blue">4</div>
              <p class="text-rothek">
                Ориентированность части курсов на конкретных работодателей с возможностью последующего трудоустройства
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
      <faq-list />
      <div class="about-page__wrapper p-0-md">
        <h2 class="text-rothek font-36 mt-40 mb-40">Социальные функции проекта «Универсариум»</h2>
        <el-row :gutter="97" class="font-24 line-30 font-16-md line-20-md mb-40">
          <el-col :xs="24" :span="12">
            <div class="cube bg-blue"></div>
            <p>
              Предоставление возможности получения качественного и доступного образования для всех желающих
            </p>
          </el-col>
          <el-col :xs="24" :span="12">
            <div class="cube bg-orange"></div>
            <p>
              Повышение привлекательности получения образования
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="97" class="font-24 line-30 font-16-md line-20-md mb-60">
          <el-col :xs="24" :span="12">
            <div class="cube bg-purple"></div>
            <p>
              Возможность дополнительного и профессионального образования для граждан проживающих на удаленных и труднодоступных территориях
            </p>
          </el-col>
          <el-col :xs="24" :span="12">
            <div class="cube bg-green"></div>
            <p>
              Доступность образования для граждан с ограниченными возможностями здоровья
            </p>
          </el-col>
        </el-row>
      </div>
      <feedback/>
    </kinesis-container>
  </div>
</template>

<script>
import FaqList from "../components/FaqList.vue";
import Feedback from "../components/Feedback.vue";

export default {
  name: "AboutPage",
  components: {Feedback, FaqList}
}
</script>