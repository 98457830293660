<template>
  <div class="institutes mt-24-md">
    <div class="institutes__buttons">
      <router-link to="/catalog" class="btn btn-lg">
        <span class="d-flex align-center">
          <img src="../assets/icons/recover.svg" />
          <span>Начать учиться</span>
        </span>
      </router-link>
      <router-link to="/catalog" class="btn btn-lg btn-without-border color-grey lg-visible">
        <span class="d-flex align-center">
          <span>смотреть все курсы</span>
        </span>
      </router-link>
    </div>
    <div class="fill fill-grey">
      <h2 class="text-rothek text-center">
        На платформе «Универсариум» свои курсы<br/> размещают 45 университетов страны. Среди которых<br/> <span class="color-purple">МГУ им. М. В. Ломоносова</span>, <span class="color-orange">РЭУ им. Г. В. Плеханова</span>,<br/> <span class="color-navy">НИЯУ МИФИ</span>, <span class="color-red">МГТУ им. Н. Э. Баумана</span>, <span class="color-blue">МФТИ</span>.
      </h2>
      <el-row class="logo-block">
        <el-col><img src="/img-front/inst-1.svg" /></el-col>
        <el-col><img src="/img-front/inst-2.svg" /></el-col>
        <el-col><img height="65" src="/img-front/inst-3.png" /></el-col>
        <el-col class="md-hidden"><img height="75" src="/img-front/inst-4.png" /></el-col>
        <el-col class="md-hidden"><img src="/img-front/inst-5.svg" /></el-col>
        <el-col class="md-hidden"><img src="/img-front/inst-1.svg" /></el-col>
        <el-col class="md-hidden"><img src="/img-front/inst-2.svg" /></el-col>
        <el-col class="md-hidden"><img height="65" src="/img-front/inst-3.png" /></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPageInstituteList"

}
</script>

<style lang="scss">
.institutes {
  &__buttons {
    padding: 40px 0;
    text-align: center;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .logo-block {
    margin-top: 32px;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
</style>