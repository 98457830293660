import VueRouter from "vue-router";
import MainPage from "./pages/MainPage.vue";
import AboutPage from "./pages/AboutPage.vue";
import PartnersPage from "./pages/PartnersPage.vue";
import CatalogPage from "./pages/CatalogPage.vue";
import ProfilePage from "./pages/ProfilePage.vue";
import CoursePage from "./pages/CoursePage.vue";
import ModulePage from "./pages/ModulePage.vue";
import CoursePageInfo from "./pages/CoursePageInfo.vue";
import ElementPage from "./pages/ElementPage.vue";

const routes = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'MainPage',
            component: MainPage,
        },
        {
            path: '/about',
            component: AboutPage,
        },
        {
            path: '/partners',
            component: PartnersPage,
        },
        {
            path: '/catalog',
            component: CatalogPage,
        },
        {
            path: '/course/:id',
            component: CoursePage,
            children: [
                {
                    path: '',
                    alias: 'info',
                    name: 'CoursePageInfo',
                    component: CoursePageInfo,
                },
                {
                    path: 'module/:mIndex',
                    component: ModulePage,
                    name: 'ModulePage',
                    children: [
                        {
                            path: 'element/:eIndex',
                            component: ElementPage,
                        }
                    ]
                }
            ]
        },
        {
            path: '/me',
            component: ProfilePage,
            meta: { auth: true }

        }
    ],
    scrollBehavior: (to) => {

        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
                offset: { x: 0, y: -1000 }
            };
        }

        return { x: 0, y: 0, behavior: 'smooth' }
    }
})

export default routes;