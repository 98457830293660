<template>
  <div class="footer mt-40">
    <div class="wrapper">
      <div class="menu">
        <div class="md-hidden">
          <nav class="menu__item d-flex align-center flex-direction-row">
            <a class="mr-40 logo" href="/"><img alt="Uni logo" src="../assets/logo.svg"></a>
            <router-link to="/catalog">Каталог курсов</router-link>
            <a target="_blank" href="https://universarium-school.ru">
              <span class="d-flex align-center flex-direction-row">
                <span class="mr-10">Детям</span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="30" rx="15" fill="#6EB253"/>
                  <path d="M20.4672 11.119C20.4672 10.7048 20.1314 10.369 19.7172 10.369L12.9672 10.369C12.5529 10.369 12.2172 10.7048 12.2172 11.119C12.2172 11.5332 12.5529 11.869 12.9672 11.869L18.9672 11.869L18.9672 17.869C18.9672 18.2832 19.3029 18.619 19.7172 18.619C20.1314 18.619 20.4672 18.2832 20.4672 17.869L20.4672 11.119ZM10.8194 21.0774L20.2475 11.6494L19.1868 10.5887L9.75873 20.0168L10.8194 21.0774Z" fill="#1F1F1F"/>
                </svg>
              </span>
            </a>
            <a target="_blank" href="https://edu-oko.ru/?id=254">
            <span class="d-flex align-center flex-direction-row">
              <span class="mr-10">СДПК</span>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="15" fill="#F7941E"/>
                <path d="M20.4672 11.119C20.4672 10.7048 20.1314 10.369 19.7172 10.369L12.9672 10.369C12.5529 10.369 12.2172 10.7048 12.2172 11.119C12.2172 11.5332 12.5529 11.869 12.9672 11.869L18.9672 11.869L18.9672 17.869C18.9672 18.2832 19.3029 18.619 19.7172 18.619C20.1314 18.619 20.4672 18.2832 20.4672 17.869L20.4672 11.119ZM10.8194 21.0774L20.2475 11.6494L19.1868 10.5887L9.75873 20.0168L10.8194 21.0774Z" fill="#1F1F1F"/>
              </svg>
            </span>
            </a>
          </nav>
        </div>
        <div class="md-visible">
          <a class="mr-40 logo" href="/"><img alt="Uni logo" src="../assets/logo.svg"></a>
        </div>
        <button @click="toTop" class="btn btn-white btn-up">
          <span>Наверх</span>
          <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.53033 0.803022C6.23744 0.510129 5.76256 0.510129 5.46967 0.803022L0.696699 5.57599C0.403805 5.86889 0.403805 6.34376 0.696699 6.63665C0.989592 6.92955 1.46447 6.92955 1.75736 6.63665L6 2.39401L10.2426 6.63665C10.5355 6.92955 11.0104 6.92955 11.3033 6.63665C11.5962 6.34376 11.5962 5.86889 11.3033 5.57599L6.53033 0.803022ZM6.75 14.6667L6.75 1.33335L5.25 1.33335L5.25 14.6667L6.75 14.6667Z" fill="#1F1F1F"/>
          </svg>
        </button>
      </div>
      <div class="md-hidden">
        <div class="footer__menu">
          <ul style="flex: 2">
            <li><a href="tel:+7 (495) 720-55-84">+7 (495) 720-55-84</a></li>
            <li><a href="mailto:info@edu-oko.ru">info@edu-oko.ru</a></li>
            <li>1115093, г. Москва, вн.тер.г. <br/>муниципальный округ Даниловский,<br/> переулок Партийный, д.1, <br/>корп.58, стр.2, этаж а4, офис 26</li>
          </ul>
          <ul style="flex: 2">
            <li><a href="/#faq">Вопросы и ответы</a></li>
            <li><router-link to="/about">О проекте</router-link></li>
            <li><router-link active-class="active" to="/partners">Партнёрам</router-link></li>
          </ul>
          <ul style="flex: 3">
            <li class="d-flex justify-space-between">
              <a href="/docs/politika-konfidencialnosti-obrabotki-personalnyh-dannyh">Политика конфиденциальности</a>
              <a href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.498 6.18598C23.3624 5.67526 23.095 5.20912 22.7226 4.83425C22.3502 4.45937 21.8858 4.18892 21.376 4.04998C19.505 3.54498 12 3.54498 12 3.54498C12 3.54498 4.495 3.54498 2.623 4.04998C2.11341 4.18917 1.64929 4.45972 1.27708 4.83456C0.904861 5.20941 0.637591 5.67542 0.502 6.18598C0 8.06998 0 12 0 12C0 12 0 15.93 0.502 17.814C0.637586 18.3247 0.904975 18.7908 1.27739 19.1657C1.64981 19.5406 2.11418 19.811 2.624 19.95C4.495 20.455 12 20.455 12 20.455C12 20.455 19.505 20.455 21.377 19.95C21.8869 19.8111 22.3513 19.5407 22.7237 19.1658C23.0961 18.7909 23.3635 18.3248 23.499 17.814C24 15.93 24 12 24 12C24 12 24 8.06998 23.498 6.18598ZM9.545 15.568V8.43198L15.818 12L9.545 15.568Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="/docs/informaciya-i-rekvizity-kompanii">Информация и реквизиты компании</a>
              <a href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_205_6662)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.16C15.203 2.16 15.585 2.176 16.85 2.231C18.02 2.286 18.655 2.48 19.077 2.646C19.639 2.863 20.037 3.123 20.459 3.542C20.878 3.962 21.138 4.361 21.355 4.923C21.519 5.345 21.715 5.98 21.768 7.15C21.825 8.416 21.838 8.796 21.838 12C21.838 15.204 21.823 15.585 21.764 16.85C21.703 18.02 21.508 18.655 21.343 19.077C21.119 19.639 20.864 20.037 20.444 20.459C20.025 20.878 19.62 21.138 19.064 21.355C18.644 21.519 17.999 21.715 16.829 21.768C15.555 21.825 15.18 21.838 11.97 21.838C8.759 21.838 8.384 21.823 7.111 21.764C5.94 21.703 5.295 21.508 4.875 21.343C4.306 21.119 3.915 20.864 3.496 20.444C3.075 20.025 2.806 19.62 2.596 19.064C2.431 18.644 2.237 17.999 2.176 16.829L2.1736 16.7617C2.13043 15.5541 2.115 15.1224 2.115 11.985C2.115 8.85068 2.13039 8.4151 2.17343 7.19686L2.176 7.124C2.237 5.954 2.431 5.31 2.596 4.89C2.806 4.32 3.075 3.93 3.496 3.509C3.915 3.09 4.306 2.82 4.875 2.611C5.295 2.445 5.926 2.25 7.096 2.19L7.15377 2.18796C8.38311 2.14455 8.79498 2.13 11.955 2.13L12 2.16ZM5.838 12C5.838 8.598 8.595 5.838 12 5.838C15.402 5.838 18.162 8.595 18.162 12C18.162 15.402 15.405 18.162 12 18.162C8.598 18.162 5.838 15.405 5.838 12ZM18.406 7.035C19.2 7.035 19.846 6.39 19.846 5.595C19.846 4.801 19.199 4.155 18.406 4.156C17.612 4.156 16.966 4.801 16.966 5.595C16.966 6.389 17.611 7.035 18.406 7.035Z" fill="#3E3E3E"/>
                    <path d="M12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16Z" fill="#3E3E3E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C8.74 0 8.333 0.015 7.053 0.072C5.775 0.132 4.905 0.333 4.14 0.63C3.351 0.936 2.681 1.347 2.014 2.014C1.347 2.681 0.935 3.35 0.63 4.14C0.333 4.905 0.131 5.775 0.072 7.053C0.012 8.333 0 8.74 0 12C0 15.26 0.015 15.667 0.072 16.947C0.132 18.224 0.333 19.095 0.63 19.86C0.936 20.648 1.347 21.319 2.014 21.986C2.681 22.652 3.35 23.065 4.14 23.37C4.906 23.666 5.776 23.869 7.053 23.928C8.333 23.988 8.74 24 12 24C15.26 24 15.667 23.985 16.947 23.928C18.224 23.868 19.095 23.666 19.86 23.37C20.648 23.064 21.319 22.652 21.986 21.986C22.652 21.319 23.065 20.651 23.37 19.86C23.666 19.095 23.869 18.224 23.928 16.947C23.988 15.667 24 15.26 24 12C24 8.74 23.985 8.333 23.928 7.053C23.868 5.776 23.666 4.904 23.37 4.14C23.064 3.351 22.652 2.681 21.986 2.014C21.319 1.347 20.651 0.935 19.86 0.63C19.095 0.333 18.224 0.131 16.947 0.072C15.667 0.012 15.26 0 12 0ZM12 2.16C15.203 2.16 15.585 2.176 16.85 2.231C18.02 2.286 18.655 2.48 19.077 2.646C19.639 2.863 20.037 3.123 20.459 3.542C20.878 3.962 21.138 4.361 21.355 4.923C21.519 5.345 21.715 5.98 21.768 7.15C21.825 8.416 21.838 8.796 21.838 12C21.838 15.204 21.823 15.585 21.764 16.85C21.703 18.02 21.508 18.655 21.343 19.077C21.119 19.639 20.864 20.037 20.444 20.459C20.025 20.878 19.62 21.138 19.064 21.355C18.644 21.519 17.999 21.715 16.829 21.768C15.555 21.825 15.18 21.838 11.97 21.838C8.759 21.838 8.384 21.823 7.111 21.764C5.94 21.703 5.295 21.508 4.875 21.343C4.306 21.119 3.915 20.864 3.496 20.444C3.075 20.025 2.806 19.62 2.596 19.064C2.431 18.644 2.237 17.999 2.176 16.829L2.1736 16.7617C2.13043 15.5541 2.115 15.1224 2.115 11.985C2.115 8.85068 2.13039 8.4151 2.17343 7.19686L2.176 7.124C2.237 5.954 2.431 5.31 2.596 4.89C2.806 4.32 3.075 3.93 3.496 3.509C3.915 3.09 4.306 2.82 4.875 2.611C5.295 2.445 5.926 2.25 7.096 2.19L7.15377 2.18796C8.38311 2.14455 8.79498 2.13 11.955 2.13L12 2.16Z" fill="#3E3E3E"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_205_6662">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="/docs/sposoby-oplaty">Способы оплаты</a>
              <a href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 12.073C24 5.446 18.627 0.072998 12 0.072998C5.373 0.072998 0 5.446 0 12.073C0 18.063 4.388 23.027 10.125 23.927V15.542H7.078V12.072H10.125V9.43C10.125 6.423 11.917 4.761 14.658 4.761C15.97 4.761 17.344 4.996 17.344 4.996V7.949H15.83C14.339 7.949 13.874 8.874 13.874 9.823V12.073H17.202L16.67 15.543H13.874V23.928C19.612 23.027 24 18.062 24 12.073Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="/docs/politika-i-usloviya-vozvratov">Политика и условия возвратов</a>
              <a href="#">
                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.953 2.57003C23.0545 2.96563 22.1026 3.22677 21.128 3.34503C22.1541 2.72828 22.9224 1.76107 23.291 0.622026C22.34 1.17703 21.286 1.58103 20.164 1.80603C19.4238 1.01446 18.4429 0.489447 17.3736 0.312528C16.3044 0.135608 15.2067 0.316677 14.2509 0.827617C13.2952 1.33856 12.5349 2.15078 12.0881 3.13815C11.6412 4.12552 11.533 5.2328 11.78 6.28803C7.69 6.09503 4.067 4.13003 1.64 1.16203C1.19879 1.91184 0.968664 2.76705 0.974 3.63703C0.974 5.34703 1.844 6.85003 3.162 7.73303C2.38086 7.70816 1.61697 7.49696 0.934 7.11703V7.17703C0.933557 8.31342 1.32627 9.41497 2.04551 10.2948C2.76474 11.1746 3.76621 11.7785 4.88 12.004C4.1583 12.1974 3.40241 12.2265 2.668 12.089C2.98412 13.067 3.59766 13.922 4.42301 14.5345C5.24837 15.1471 6.24435 15.4867 7.272 15.506C5.53153 16.872 3.38248 17.6133 1.17 17.611C0.78 17.611 0.391 17.588 0 17.544C2.25571 18.9884 4.87851 19.755 7.557 19.753C16.61 19.753 21.555 12.257 21.555 5.76803C21.555 5.55803 21.555 5.34803 21.54 5.13803C22.506 4.44289 23.3392 3.57985 24 2.59003L23.953 2.57003Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
            <li class="d-flex justify-space-between">
              <a href="/docs/informaciya-o-zaschite-platejnyh-dannyh-pokupatelya">О защите платежных данных покупателя</a>
              <a href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.489 0.00397656L10.218 0.000976562H13.782L14.512 0.00397656L15.426 0.0139766L15.859 0.0209766L16.277 0.0319766L16.68 0.0459766L17.068 0.0619766L17.442 0.0829766L17.802 0.107977L18.147 0.137977L18.48 0.170977C20.22 0.366977 21.413 0.786977 22.313 1.68698C23.213 2.58698 23.633 3.77898 23.829 5.51998L23.863 5.85298L23.892 6.19898L23.917 6.55898L23.937 6.93198L23.962 7.51998L23.974 7.92998L23.987 8.57398L23.996 9.48898L24 10.469L23.999 13.782L23.996 14.512L23.986 15.426L23.979 15.859L23.968 16.277L23.954 16.68L23.938 17.068L23.917 17.442L23.892 17.802L23.862 18.147L23.829 18.48C23.633 20.22 23.213 21.413 22.313 22.313C21.413 23.213 20.221 23.633 18.48 23.829L18.147 23.863L17.801 23.892L17.441 23.917L17.068 23.937L16.48 23.962L16.07 23.974L15.426 23.987L14.511 23.996L13.531 24L10.218 23.999L9.488 23.996L8.574 23.986L8.141 23.979L7.723 23.968L7.32 23.954L6.932 23.938L6.558 23.917L6.198 23.892L5.853 23.862L5.52 23.829C3.78 23.633 2.587 23.213 1.687 22.313C0.787 21.413 0.367 20.221 0.171 18.48L0.137 18.147L0.108 17.801L0.083 17.441L0.063 17.068L0.038 16.48L0.026 16.07L0.013 15.426L0.004 14.511L0 13.531L0.001 10.218L0.004 9.48798L0.014 8.57398L0.021 8.14098L0.032 7.72298L0.046 7.31998L0.062 6.93198L0.083 6.55798L0.108 6.19798L0.138 5.85298L0.171 5.51998C0.367 3.77998 0.787 2.58698 1.687 1.68698C2.587 0.786977 3.779 0.366977 5.52 0.170977L5.853 0.136977L6.199 0.107977L6.559 0.0829766L6.932 0.0629766L7.52 0.0379766L7.93 0.0259766L8.574 0.0129766L9.489 0.00397656ZM6.79 7.29998H4.05C4.18 13.54 7.3 17.29 12.77 17.29H13.08V13.72C15.09 13.92 16.61 15.39 17.22 17.29H20.06C19.28 14.45 17.23 12.88 15.95 12.28C17.23 11.54 19.03 9.73998 19.46 7.29998H16.88C16.32 9.27998 14.66 11.08 13.08 11.25V7.29998H10.5V14.22C8.9 13.82 6.88 11.88 6.79 7.29998Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="md-visible">
        <div class="footer__menu d-block mt-28">
          <nav class="menu__item">
            <div class="d-flex align-center justify-space-between mt-30">
              <router-link to="/catalog">Каталог курсов</router-link>
            </div>
            <div class="d-flex align-center justify-space-between mt-30">
              <a href="/catalog">Партнерам</a>
              <a target="_blank" href="https://edu-oko.ru/?id=254">
                <span class="mr-10">СДПК</span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="30" rx="15" fill="#F7941E"/>
                  <path d="M20.4672 11.119C20.4672 10.7048 20.1314 10.369 19.7172 10.369L12.9672 10.369C12.5529 10.369 12.2172 10.7048 12.2172 11.119C12.2172 11.5332 12.5529 11.869 12.9672 11.869L18.9672 11.869L18.9672 17.869C18.9672 18.2832 19.3029 18.619 19.7172 18.619C20.1314 18.619 20.4672 18.2832 20.4672 17.869L20.4672 11.119ZM10.8194 21.0774L20.2475 11.6494L19.1868 10.5887L9.75873 20.0168L10.8194 21.0774Z" fill="#1F1F1F"/>
                </svg>
              </a>
            </div>
            <div class="d-flex align-center justify-space-between mt-30">
              <router-link to="/about">О проекте</router-link>
              <a target="_blank" href="https://universarium-school.ru">
                <span class="d-flex align-center flex-direction-row">
                  <span class="mr-10">Детям</span>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" rx="15" fill="#6EB253"/>
                    <path d="M20.4672 11.119C20.4672 10.7048 20.1314 10.369 19.7172 10.369L12.9672 10.369C12.5529 10.369 12.2172 10.7048 12.2172 11.119C12.2172 11.5332 12.5529 11.869 12.9672 11.869L18.9672 11.869L18.9672 17.869C18.9672 18.2832 19.3029 18.619 19.7172 18.619C20.1314 18.619 20.4672 18.2832 20.4672 17.869L20.4672 11.119ZM10.8194 21.0774L20.2475 11.6494L19.1868 10.5887L9.75873 20.0168L10.8194 21.0774Z" fill="#1F1F1F"/>
                  </svg>
                </span>
              </a>
            </div>
          </nav>
          <ul class="footer__menu__big pt-40 pb-10 mt-44">
            <li class="mb-30"><a href="/#faq">Вопросы и ответы</a></li>
            <li class="mb-30"><router-link to="/about">О проекте</router-link></li>
            <li class="mb-30"><router-link active-class="active" to="/partners">Партнёрам</router-link></li>
          </ul>
          <ul class="footer__menu__small pt-40 pb-10">
            <li class="mb-30">
              <a href="/docs/politika-konfidencialnosti-obrabotki-personalnyh-dannyh">Политика конфиденциальности</a>
            </li>
            <li class="mb-30">
              <a href="/docs/informaciya-i-rekvizity-kompanii">Информация и реквизиты компании</a>
            </li>
            <li class="mb-30">
              <a href="/docs/sposoby-oplaty">Способы оплаты</a>
            </li>
            <li class="mb-30">
              <a href="/docs/politika-i-usloviya-vozvratov">Политика и условия возвратов</a>
            </li>
            <li class="mb-30">
              <a href="/docs/informaciya-o-zaschite-platejnyh-dannyh-pokupatelya">О защите платежных данных покупателя</a>
            </li>
          </ul>
          <ul class="footer__menu__small pt-40 pb-10">
            <li class="d-flex justify-space-around">
              <a href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.498 6.18598C23.3624 5.67526 23.095 5.20912 22.7226 4.83425C22.3502 4.45937 21.8858 4.18892 21.376 4.04998C19.505 3.54498 12 3.54498 12 3.54498C12 3.54498 4.495 3.54498 2.623 4.04998C2.11341 4.18917 1.64929 4.45972 1.27708 4.83456C0.904861 5.20941 0.637591 5.67542 0.502 6.18598C0 8.06998 0 12 0 12C0 12 0 15.93 0.502 17.814C0.637586 18.3247 0.904975 18.7908 1.27739 19.1657C1.64981 19.5406 2.11418 19.811 2.624 19.95C4.495 20.455 12 20.455 12 20.455C12 20.455 19.505 20.455 21.377 19.95C21.8869 19.8111 22.3513 19.5407 22.7237 19.1658C23.0961 18.7909 23.3635 18.3248 23.499 17.814C24 15.93 24 12 24 12C24 12 24 8.06998 23.498 6.18598ZM9.545 15.568V8.43198L15.818 12L9.545 15.568Z" fill="#3E3E3E"/>
                </svg>
              </a>
              <a href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.489 0.00397656L10.218 0.000976562H13.782L14.512 0.00397656L15.426 0.0139766L15.859 0.0209766L16.277 0.0319766L16.68 0.0459766L17.068 0.0619766L17.442 0.0829766L17.802 0.107977L18.147 0.137977L18.48 0.170977C20.22 0.366977 21.413 0.786977 22.313 1.68698C23.213 2.58698 23.633 3.77898 23.829 5.51998L23.863 5.85298L23.892 6.19898L23.917 6.55898L23.937 6.93198L23.962 7.51998L23.974 7.92998L23.987 8.57398L23.996 9.48898L24 10.469L23.999 13.782L23.996 14.512L23.986 15.426L23.979 15.859L23.968 16.277L23.954 16.68L23.938 17.068L23.917 17.442L23.892 17.802L23.862 18.147L23.829 18.48C23.633 20.22 23.213 21.413 22.313 22.313C21.413 23.213 20.221 23.633 18.48 23.829L18.147 23.863L17.801 23.892L17.441 23.917L17.068 23.937L16.48 23.962L16.07 23.974L15.426 23.987L14.511 23.996L13.531 24L10.218 23.999L9.488 23.996L8.574 23.986L8.141 23.979L7.723 23.968L7.32 23.954L6.932 23.938L6.558 23.917L6.198 23.892L5.853 23.862L5.52 23.829C3.78 23.633 2.587 23.213 1.687 22.313C0.787 21.413 0.367 20.221 0.171 18.48L0.137 18.147L0.108 17.801L0.083 17.441L0.063 17.068L0.038 16.48L0.026 16.07L0.013 15.426L0.004 14.511L0 13.531L0.001 10.218L0.004 9.48798L0.014 8.57398L0.021 8.14098L0.032 7.72298L0.046 7.31998L0.062 6.93198L0.083 6.55798L0.108 6.19798L0.138 5.85298L0.171 5.51998C0.367 3.77998 0.787 2.58698 1.687 1.68698C2.587 0.786977 3.779 0.366977 5.52 0.170977L5.853 0.136977L6.199 0.107977L6.559 0.0829766L6.932 0.0629766L7.52 0.0379766L7.93 0.0259766L8.574 0.0129766L9.489 0.00397656ZM6.79 7.29998H4.05C4.18 13.54 7.3 17.29 12.77 17.29H13.08V13.72C15.09 13.92 16.61 15.39 17.22 17.29H20.06C19.28 14.45 17.23 12.88 15.95 12.28C17.23 11.54 19.03 9.73998 19.46 7.29998H16.88C16.32 9.27998 14.66 11.08 13.08 11.25V7.29998H10.5V14.22C8.9 13.82 6.88 11.88 6.79 7.29998Z" fill="#3E3E3E"/>
                </svg>
              </a>
              <a href="#">
                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.953 2.57003C23.0545 2.96563 22.1026 3.22677 21.128 3.34503C22.1541 2.72828 22.9224 1.76107 23.291 0.622026C22.34 1.17703 21.286 1.58103 20.164 1.80603C19.4238 1.01446 18.4429 0.489447 17.3736 0.312528C16.3044 0.135608 15.2067 0.316677 14.2509 0.827617C13.2952 1.33856 12.5349 2.15078 12.0881 3.13815C11.6412 4.12552 11.533 5.2328 11.78 6.28803C7.69 6.09503 4.067 4.13003 1.64 1.16203C1.19879 1.91184 0.968664 2.76705 0.974 3.63703C0.974 5.34703 1.844 6.85003 3.162 7.73303C2.38086 7.70816 1.61697 7.49696 0.934 7.11703V7.17703C0.933557 8.31342 1.32627 9.41497 2.04551 10.2948C2.76474 11.1746 3.76621 11.7785 4.88 12.004C4.1583 12.1974 3.40241 12.2265 2.668 12.089C2.98412 13.067 3.59766 13.922 4.42301 14.5345C5.24837 15.1471 6.24435 15.4867 7.272 15.506C5.53153 16.872 3.38248 17.6133 1.17 17.611C0.78 17.611 0.391 17.588 0 17.544C2.25571 18.9884 4.87851 19.755 7.557 19.753C16.61 19.753 21.555 12.257 21.555 5.76803C21.555 5.55803 21.555 5.34803 21.54 5.13803C22.506 4.44289 23.3392 3.57985 24 2.59003L23.953 2.57003Z" fill="#3E3E3E"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-120 text-center md-hidden text-footer">
        © ООО «Оценка качества образования» 2013-{{ (new Date).getFullYear() }}. Все права защищены.<br/>
        <small>Логотип и написание «Универсариум» являются зарегистрированными товарными знаками ООО «Оценка качества образования». Дата и номер государственной регистрации отчуждения исключительного права на товарный знак 27.10.2020 г. № РД0344909 (изменения к свидетельству № 527023, изменения к свидетельству № 527024). Свидетельство о государственной регистрации программы "Траектория" для ЭВМ №2020618480 от 29.07.2020.</small>
      </div>
      <div class="mt-30 text-center md-visible text-footer">
        © ООО «Оценка качества образования» 2013-{{ (new Date).getFullYear() }}. Все права защищены.<br/>
        <small class="text-left">Логотип и написание «Универсариум» являются зарегистрированными товарными знаками ООО «Оценка качества образования». Дата и номер государственной регистрации отчуждения исключительного права на товарный знак 27.10.2020 г. № РД0344909 (изменения к свидетельству № 527023, изменения к свидетельству № 527024). Свидетельство о государственной регистрации программы "Траектория" для ЭВМ №2020618480 от 29.07.2020.</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutFooter",
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style lang="scss">
.footer {
  background: #F5F5F5;
  color: #A5A5A5;
  padding: 39px 24px;
  margin: 0;
  .menu {
    display: flex!important;
    .logo {
      img {
        max-width: 90%
      }
    }
    @media screen and (max-width: 768px) {
      &__item a {
        color: #1F1F1F;
      }
    }
  }
  &__menu {
    display: flex;
    padding: 52px 117px 0;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        color: #A5A5A5;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          text-decoration: none;
          color: #A5A5A5;
          &:hover {
            color: #1f1f1f;
          }
        }
      }
    }
    &__big, &__small {
      border-top: 2px solid #EBEEF5;
    }
    &__small {
      font-weight: 400;
      font-size: 12px;
      line-height: 135%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 39px 0;
  }
  .text-footer {
    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 135%;
    }
  }
}
</style>