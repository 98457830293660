<template>
  <div class="badge bg-dark color-white mr-10">
    <template v-if="course.institution">
      {{ course.institution.shortName }}
    </template>
    <template v-else>
      ОКО
    </template>
  </div>
</template>

<script>
import courseMixin from "../../mixin/courseMixin";

export default {
  name: "CourseInstitute",
  mixins: [courseMixin]
}
</script>

<style scoped>

</style>