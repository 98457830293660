<template>
  <div class="fill fill-grey mt-24" ref="courseContent">
    <template >
      <h2 class="text-rothek font-36 mb-20 mt-20">
        <template v-if="!course.enroll">
          Содержание<br/> курса
        </template>
        <template v-else>
          Структура курса <br/>и мой прогресс
        </template>
      </h2>
      <module-list />
    </template>
  </div>
</template>

<script>
import {eventBus} from "../../main";
import {mapGetters} from "vuex";
import ModuleList from "../module/ModuleList.vue";

export default {
  name: "CourseContent",
  components: {ModuleList},
  mounted() {
    eventBus.$on('enroll:course', () => {
      this.$refs.courseContent.scrollIntoView({ behavior: 'smooth' });
    })
  },
  computed: {
    ...mapGetters(['course'])
  }
}
</script>

<style scoped>

</style>