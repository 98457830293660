import Vue from 'vue'
import Vuex from 'vuex'
import course from "./course";
import categories from "./categories";
import certificates from "./certificates";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        course,
        categories,
        certificates
    }
})

