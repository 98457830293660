<template>
  <div class="ml-16 tutor-item align-items-center">
    <h2 class="font-16 line-24">{{ tutor.user.lastName }} {{ tutor.user.firstName }}<br v-if="tutor.user.middleName" />{{ tutor.user.middleName }}</h2>
    <p class="font-14 line-16" v-html="tutor.degree"></p>
  </div>
</template>

<script>
export default {
  name: "TutorData",
  props: {
    tutor: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.tutor-item {
  max-height: 154px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
</style>