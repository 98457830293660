<template>
  <div class="align-self-end mt-50 ml-20">
    <button v-if="!course.enroll" @click="enroll()" class="btn btn-black">
      <spin-load :show="loading"  />
    </button>
    <button v-else @click="unEnroll()" class="btn btn-black">
      <spin-load :show="loading">Отписаться</spin-load>
    </button>
  </div>
</template>

<script>
import {eventBus} from "../../main";
import {mapGetters, mapMutations} from "vuex";
import TYPES from "../../../../src/components/User/actionsTypes";
import actionsMixin from "../../mixin/actionsMixin";
import SpinLoad from "../SpinLoad.vue";

export default {
  name: "CourseEnrollButton",
  components: {SpinLoad},
  mixins: [actionsMixin],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async enroll() {
      if(!this.$auth.check()) {
        eventBus.$emit('show:login')
      } else {
        this.loading = true
        let enroll = await this.axios.post(`enrollments/${this.course.id}`, {'isActive': true})
        this.addAction(TYPES.COURSE_ENROLL_TYPE, this.course.name)
        this.loading = false
        this.setCourse({...this.course, enroll: enroll})
        eventBus.$emit('enroll:course')
      }
    },
    async unEnroll() {
      this.loading = true
      await this.axios.post(`enrollments/${this.course.id}`, {'isActive': false})
      this.addAction(TYPES.COURSE_UN_ENROLL_TYPE, this.course.name)
      this.loading = false
      this.setCourse({...this.course, enroll: null})
    },
    ...mapMutations(['setCourse'])
  },
  computed: {
    ...mapGetters(['course'])
  }
}
</script>

<style scoped>

</style>