<template>
  <div class="player-box box-sizing">
    <video-player class="video-lectures__player-box"
                  ref="videoPlayer"
                  :key="video.id"
                  :options="playerOptions"
                  :playsinline="true"
                  @play="onPlayerPlay($event)"
                  @pause="onPlayerPause($event)"
                  @ready="playerReadied"
                  @timeupdate="timeUpdate($event)"
    >
    </video-player>
  </div>
</template>

<script>
import 'videojs-resolution-switcher'
import 'videojs-resolution-switcher/lib/videojs-resolution-switcher.css'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "BaseVideoPlayer",
  data() {
    return {
      playerOptions: {
        language: 'ru',
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          src: this.video.thumbs['720p'],
          type: this.video.mimeType,
          label: '720p'
        }],
        plugins: {
          videoJsResolutionSwitcher: {
            default: 'low',
            dynamicLabel: true
          }
        },
        poster: this.video.thumbs.cover,
      },
      isPlay: false,
      isFullScreen: false
    }
  },
  props: {
    video: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    ...mapGetters(['element'])
  },
  methods: {
    ...mapActions(['getTest', 'getQuestions', 'getElement', 'getModule', 'updCourse']),
    onPlayerPlay() {
      this.isPlay = true
    },
    onPlayerPause() {
      this.isPlay = false
    },
    timeUpdate(player) {
      if(this.element.elementResult && player.currentTime() >= 19 && player.currentTime() <= 20 && this.element.elementResult.status !== 'success') {
        this.axios.put(`element_results/${this.element.elementResult.id}`, {status: 'success', hideLoader: true}).then(async () => {
          await this.updCourse(this.$route.params.id)
          await this.getModule(this.$route.params.mIndex)
          await this.getElement(this.$route.params.eIndex)
        });
      }
    },
    playerReadied(player) {
      player.updateSrc([
        { type: this.video.mimeType, src: this.video.thumbs['360p'], label: '360p' },
        { type: this.video.mimeType, src: this.video.thumbs['480p'], label: '480p' },
        { type: this.video.mimeType, src: this.video.thumbs['720p'], label: '720p' },
      ])
    }
  }
}
</script>

<style lang="scss">
.player-box {
  position: relative;
  border-radius: 24px;
  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    left: 30%;
  }
}
</style>