<template>
  <div class="test__info">
    <div class="row test">
      <div class="col-md-6 col-sm-6 fill fill-green p-30 pr-28 pl-28" style="position: relative;">
        <div class="d-flex flex-column justify-space-between">
          <div class="text-rothek font-25">
            Время<br/> на тест
          </div>
          <div class="d-flex" style="position: absolute; bottom: 30px;">
            <div class="text-rothek font-100 color-dark">
              {{ test.duration }}
            </div>
            <div class="text-rothek font-80 color-dark-mute align-self-end">
              мин.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12 col-sm-12 fill fill-purple mb-15 p-30 pr-28 pl-28">
          <div class="d-flex justify-content-between">
            <div class="text-rothek font-25">
              Количество<br/>вопросов
            </div>
            <div class="text-rothek font-100 color-dark" style="align-self: last baseline;">
              {{ test.questionsCount }}
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12  fill fill-orange p-30 pr-28 pl-28">
          <div class="d-flex flex-column justify-content-between">
            <div class="text-rothek font-25">
              Осталось<br/>попыток
            </div>
            <div class="text-rothek font-100 color-dark mt-30">
              {{ test.elapsedAttemptsCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20 d-flex justify-content-end" v-if="test.elapsedAttemptsCount">
      <button @click.prevent="start()" class="btn btn-sm btn-black">Начать тест</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "../../main";

export default {
  name: "TestStartInfo",
  props: {
    test: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(['getTest', 'getQuestions', 'getElement', 'getModule', 'updCourse']),
    async start() {
      await this.getQuestions(this.testId)
      await this.getTest(this.testId)
    }
  },
  mounted() {
    eventBus.$on('start:test', () => {
      this.start()
    })
  },
  computed: {
    testId() {
      return this.element.test.id
    },
    ...mapGetters(['element'])
  }
}
</script>

<style scoped>

</style>