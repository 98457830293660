import axios from "axios";
export default {
    state: {
        certificates: [],
        myCertificates: null,
        stats: {}
    },
    actions: {
        async getMyCertificates(ctx) {
            let res = await axios.get('certificates/my')
            ctx.commit('setMyCertificates', res.data)
        }
    },
    mutations: {
        setMyCertificates(state, certificates) {
            state.myCertificates = certificates
        }
    },
    getters: {
        myCertificates(state) {
            return state.myCertificates
        }
    }
}