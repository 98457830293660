<template>
  <div class="about-page">
    <kinesis-container event="scroll" class="container">
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-first">
          <img src="../assets/images/kinesis-first.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-second">
          <img src="../assets/images/kinesis-second.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-third">
          <img src="../assets/images/kinesis-third.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-fourth">
          <img src="../assets/images/kinesis-fourth.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-five">
          <img src="../assets/images/kinesis-five.svg" />
        </div>
      </kinesis-element>
      <div class="about-page__wrapper about-page__wrapper-partners">
        <h1 class="text-rothek font-104 mb-60">Партнёрам</h1>
        <h2 class="text-rothek font-36 mb-10">Уважаемые ректоры и преподаватели ВУЗов!</h2>
        <p class="font-20 line-27 font-16-md line-20-md mb-60 mb-50-md">
          Предлагаем разместить на платформе "Универсариум" курсы формата МООК, созданные вами и вашим универститетом. Публикуя курсы у нас, вы повышаете узнаваемость вашего вуза и привлекаете мотивированных абитуриентов из России и зарубежья.
        </p>
        <el-row :gutter="40" class="font-20 line-27 font-16-md line-20-md">
          <el-col :xs="24" :span="12" class="mb-8">
            <div class="text-rothek fill fill-grey fill-grey-sm pr-160">
              <div class="font-104 color-blue font-24-md line-25-md">2 116 108</div>
              <div class="mt-10">Слушателей обучаются<br/> на площадке</div>
            </div>
          </el-col>
          <el-col :xs="24" :span="12" class="mb-100 mb-60-md">
            <div class="text-rothek fill fill-grey-outline fill-grey-sm">
              <div class="mt-10">
                <span class="font-104 color-green font-24-md line-25-md">24%</span> из них<br/>
                иностранные слушатели (Республика Беларусь, Казахстан, Украина и др.)
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="mt-120 mt-60-md mb-40 text-rothek font-48 line-53 font-36-md line-40-md text-center-md">
          В настоящее время на платформе <br/>«Универсариум» размещено<br/> более 80 курсов от 30 ведущих<br/> университетов страны
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :span="8" class="mb-24">
            <div class="fill bg-green fill-sm">
              <div class="text-rothek mb-50 font-24 line-30">Мотивационные</div>
              Ранние профориентационные курсы для школьников 7-10-х классов
            </div>
          </el-col>
          <el-col :xs="24" :span="8" class="mb-24">
            <div class="fill bg-blue fill-sm">
              <div class="text-rothek mb-20 font-24 line-30">Просветительские и информационные</div>
              Курсы, предназначенные для широкой аудитории.
            </div>
          </el-col>
          <el-col :xs="24" :span="8" class="mb-24">
            <div class="fill bg-orange fill-sm">
              <div class="text-rothek mb-20 font-24 line-30">Курсы дополнительного образования</div>
              Ранние профориентационные курсы для школьников 7-10-х классов
            </div>
          </el-col>
        </el-row>
        <h2 class="text-rothek font-36 mt-150 mb-40">
          Команда «Универсариума»<br/>проводит:
        </h2>
        <el-row :gutter="20">
          <el-col :xs="24" :span="10" class="mb-24">
            <div class="fill fill-grey fill-sm text-center-md">
              <div class="circle-number mb-20 bg-purple">1</div>
              <div class="text-rothek mb-50 font-24 line-30">
                Аудит научных и образовательных школ и аргументированный выбор наиболее перспективных направлений
              </div>
              В рамках которых будут созданы курсы МООК под брендом вашего Университета
            </div>
          </el-col>
          <el-col :xs="24" :span="10" class="mb-24">
            <div class="fill fill-grey fill-sm text-center-md">
              <div class="circle-number mb-20 bg-red">2</div>
              <div class="text-rothek mb-80 font-24 line-30">
                Консультационные услуги по подготовке преподавателей – будущих авторов курсов,
                и производству курсов
              </div>
              Методическая и техническая поддержка вуза при самостоятельном производстве курсов
            </div>
          </el-col>
          <el-col :xs="24" :span="4"></el-col>
        </el-row>
        <div class="mt-120 mt-60-md mb-40 text-rothek font-48 line-53 font-36-md line-40-md text-center-md">
          Участие в проекте «Универсариум» позволит вашему Университету заявить о себе в России и за рубежом. Поможет привлечь в ваш вуз лучших и мотивированных студентов.
        </div>
        <div class="text-center-md">
          <a href="/" class="btn btn-white btn-outline btn-border mt-40 mb-40" target="_blank">
            <div class="d-flex justify-space-between align-center">
              <svg class="mr-20" width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.50371 -6.10352e-05C0.702898 -6.10352e-05 0.0537109 0.649126 0.0537109 1.44994V24.6499C0.0537109 27.0524 2.00127 28.9999 4.40371 28.9999H19.6916C22.094 28.9999 24.0416 27.0524 24.0416 24.6499V8.69994C24.0416 8.3058 23.8811 7.92865 23.5972 7.6553L16.0658 0.405306C15.7956 0.145229 15.4352 -6.10352e-05 15.0602 -6.10352e-05H1.50371ZM15.0064 3.41086L20.5008 8.69994H15.0064V3.41086ZM6.04796 15.95C6.04796 15.1492 6.69714 14.5 7.49796 14.5H16.498C17.2988 14.5 17.948 15.1492 17.948 15.95C17.948 16.7508 17.2988 17.4 16.498 17.4H7.49796C6.69714 17.4 6.04796 16.7508 6.04796 15.95ZM6.04796 21.75C6.04796 20.9492 6.69714 20.3 7.49796 20.3H16.498C17.2988 20.3 17.948 20.9492 17.948 21.75C17.948 22.5508 17.2988 23.2 16.498 23.2H7.49796C6.69714 23.2 6.04796 22.5508 6.04796 21.75Z" fill="#00AEEF"/>
              </svg>
              СКАЧАТЬ КРАТКОЕ ТЗ
            </div>
          </a>
        </div>
      </div>
      <feedback fill="orange" />
    </kinesis-container>
  </div>
</template>

<script>
import Feedback from "../components/Feedback.vue";
export default {
  name: "PartnersPage",
  components: {Feedback}
}
</script>

<style scoped>

</style>