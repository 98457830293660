<template>
<div>
  <course-description />
  <div class="mt-24" v-if="course.promo">
    <base-video-player :video="course.promo" />
  </div>
  <course-content />
  <course-author-list v-if="course.courseTutors.length" />
  <feedback fill="grey" />
  <same-course-carousel v-if="course.category" :category="course.category" />
</div>
</template>

<script>
import CourseDescription from "../components/course/CourseDescription.vue";
import BaseVideoPlayer from "../components/element/BaseVideoPlayer.vue";
import CourseContent from "../components/course/CourseContent.vue";
import CourseAuthorList from "../components/course/CourseAuthorList.vue";
import Feedback from "../components/Feedback.vue";
import SameCourseCarousel from "../components/course/SameCourseCarousel.vue";
import {mapGetters} from "vuex";

export default {
  name: "CoursePageInfo",
  components: {SameCourseCarousel, Feedback, CourseAuthorList, CourseContent, BaseVideoPlayer, CourseDescription},
  computed: {
    ...mapGetters(['course'])
  }
}
</script>

<style scoped>

</style>