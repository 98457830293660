<template>
  <div>
    <test-question-name :current-key="currentKey" :question="question" />
    <div class="form-checkbox" v-for="(answer) in question.answers" :key="answer.id" :class="{correct: answer.correct && answer.correct === true, incorrect: answer.correct === false}">
      <div class="form-checkbox__wrapper">
        <input type="checkbox" :value="answer.id" :id="'multiple_' + answer.id" v-model="selected[answer.id]" />
        <label :for="'multiple_' + answer.id" v-html="answer.name" class="ml-20 font-18 mt-20"></label>
      </div>
    </div>
  </div>
</template>

<script>
import vmMixin from "../../mixin/vmMixin";
import TestQuestionName from "./TestQuestionName.vue";
import testQuestionMixin from "../../mixin/testQuestionMixin";

export default {
  name: "TestQuestionMultiple",
  components: {TestQuestionName},
  mixins: [vmMixin, testQuestionMixin],
  data() {
    return {
      selected: this.question.selected ? this.question.selected : {}
    }
  },
  watch: {
    selected(val) {
      this.model = val
    }
  }
}
</script>

<style scoped>

</style>