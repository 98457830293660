<template>
  <div v-if="course" @click="toCourse()" class="course-card fill fill-grey fill fill-grey-sm">
    <div>
      <div class="course-card__status-list">
        <div class="course-card__status-list-left">
          <span class="badge bg-yellow">
            {{ (course.category ? course.category.title : 'Популярный')  | truncate(30, '...')  }}
          </span>
        </div>
        <div class="course-card__status-list-right">
          <span class="badge bg-white">
            {{ course.statusRu ? course.statusRu : 'СКОРО' }}
          </span>
        </div>
      </div>
      <div class="course-card__header">
      <div>
        <div class="course-card__header__cover"
             v-if="typeof course.cover === 'string'"
             :style="{backgroundImage: 'url(' + course.cover + ')'}">
        </div>
        <div class="course-card__header__cover"
             v-else-if="course.cover.thumbs"
             :style="{backgroundImage: 'url(' + course.cover.thumbs.smaller + ')'}">
        </div>
      </div>
      <div class="course-card__header__title">
        {{ course.name | truncate(100, '...') }}
      </div>
    </div>
    </div>
    <div class="course-card__description">
      <template v-if="course.shortDescription">
        {{ strippedContent(course.shortDescription) | truncate(200, '...') }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseCard",
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  methods: {
    strippedContent(content) {
      let regex = /(<([^>]+)>)/ig;
      return content.replace(regex, "");
    },
    toCourse() {
      window.location.href = '/course/' + this.course.id;
    }
  }
}
</script>