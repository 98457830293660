<template>
  <el-row :gutter="20" class="courses-list courses-list-loading">
    <template v-if="size() === 3">
      <el-col class="courses-list__item" :lg="lg" :md="24" :sm="24" :xs="24">
        <div class="course-card fill fill-grey fill fill-grey-sm skeleton"></div>
      </el-col>
      <el-col class="courses-list__item" :lg="lg" :md="24" :sm="24" :xs="24">
        <div class="course-card fill fill-grey fill fill-grey-sm skeleton"></div>
      </el-col>
      <el-col class="courses-list__item" :lg="lg" :md="24" :sm="24" :xs="24">
        <div class="course-card fill fill-grey fill fill-grey-sm skeleton"></div>
      </el-col>
    </template>
    <template v-else-if="size() === 2">
      <el-col class="courses-list__item" :lg="lg" :md="24" :sm="24" :xs="24">
        <div class="course-card fill fill-grey fill fill-grey-sm skeleton"></div>
      </el-col>
      <el-col class="courses-list__item" :lg="lg" :md="24" :sm="24" :xs="24">
        <div class="course-card fill fill-grey fill fill-grey-sm skeleton"></div>
      </el-col>
    </template>
    <template v-else>
      <el-col class="courses-list__item" :lg="lg" :md="24" :sm="24" :xs="24">
        <div class="course-card fill fill-grey fill fill-grey-sm skeleton"></div>
      </el-col>
    </template>
  </el-row>
</template>

<script>
import sizeMixin from "../../mixin/sizeMixin";

export default {
  name: "CourseListPreloader",
  mixins: [sizeMixin],
}
</script>

<style scoped>

</style>