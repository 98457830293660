import axios from "axios";
export default {
    state: {
        courses: [],
        myCourses: null,
        stats: {},
        course: null,
        module: null,
        element: null,
        test: {},
        questions: [],
    },
    actions: {
        async getCourses(ctx) {
            let res = await axios.get('courses-list', {
                params: {
                    'filters[isActive]': true,
                    'filters[isClosed]': false
                }
            })
            ctx.commit('setCourses', res.data.data)
        },
        async getMyCourses(ctx) {
            let res = await axios.get('courses', {params: {'my': true}})
            ctx.commit('setMyCourses', res.data)
        },
        async getMyStats(ctx) {
            let res = await axios.get('course-stat')
            ctx.commit('setStats', res.data)
        },
        async getCourse(ctx, id) {
            ctx.commit('setCourse', null)
            let res = await axios.get('courses/' + id)
            ctx.commit('setCourse', res.data)
        },
        async updCourse(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.get('courses/' + id)
                    .then(res => {
                        resolve(ctx.commit('setCourse', res.data))
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        async getModule(ctx, index) {
            const module = ctx.state.course.modules.find((module) => {
                return module.position == index
            })
            ctx.commit('setModule', module)
        },
        async getElement(ctx, index) {
            const element = ctx.state.module.moduleElements.find((element) => {
                return element.position == index
            })
            setTimeout(() => {
                ctx.commit('setElement', element)
            }, 500)
        },
        async getTest(ctx, id) {
            ctx.commit('setTest', null)
            let test = await axios.get(`tests/${id}/pass-status`)
            ctx.commit('setTest', test.data)
        },
        async getQuestions(ctx, id) {
            ctx.commit('setQuestions', null)
            let res = await axios.get(`tests/${id}/pass-process`)
            ctx.commit('setQuestions', res.data)
        }
    },
    mutations: {
        setCourses(state, courses) {
            state.courses = courses
        },
        setMyCourses(state, courses) {
            state.myCourses = courses
        },
        setStats(state, stats) {
            state.stats = stats
        },
        setCourse(state, data) {
            if(data) {
                data.modules = data.modules.map((module) => {
                    module.moduleElements = module.moduleElements.map((element, index) => {
                        if(index && module.moduleElements[index-1] && element.elementResult) {
                            element.elementResult.status = module.moduleElements[index-1].elementResult.status === "success"
                                ? ((element.elementResult.status === 'success' || element.elementResult.status === 'failed') ? 'success' :  'active' )
                                : 'locked';
                        }
                        return element;
                    })
                    return module;
                })
            }
            state.course = data
        },
        setModule(state, data) {
            state.module = data
        },
        setElement(state, data) {
            state.element = data
        },
        setTest(state, data) {
            state.test = data
        },
        setQuestions(state, data) {
            state.questions = data
        }
    },
    getters: {
        allCourses(state) {
            return state.courses
        },
        myCourses(state) {
            return state.myCourses
        },
        myStats(state) {
            return state.stats
        },
        course(state) {
            return state.course
        },
        module(state) {
            return state.module
        },
        element(state) {
            return state.element
        },
        test(state) {
            return state.test
        },
        questions(state) {
            return state.questions
        },
    }
}