export const AppConfig = {
    facebookAppId: '1460245450759375',
    vkClientId: '6226262',
    okClientId: '1250008576',
    yandexAppId: 'bf8a9810c54f46978bf12f4989624d57',
    googleAppId: '850950583011-t95ah0a0auf3cth743hmpmb8bdcr9s01.apps.googleusercontent.com',
    pusherAppKey: '8c5183fabd14ad6259d1'
};

export const SocialNetwork = {
    facebook: 'facebook',
    vk: 'vk',
    ok: 'ok',
    yandex: 'yandex',
    google: 'google'
};
