<template>
  <div class="fill" v-if="sameCourses.length > 1">
    <h2 class="text-rothek font-36 mb-24">Похожие<br/>курсы</h2>
    <carousel :per-page="3"
              :mouse-drag="true"
              :pagination-enabled="false"
              :navigation-enabled="true"
              :perPageCustom="[[320, 1], [1199, 3]]"
              navigationNextLabel='<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="12.25" stroke="black" stroke-width="1.5"/><path d="M10 7L16 13L10 19" stroke="#1F1F1F" stroke-width="1.5"/></svg>'
              navigationPrevLabel='<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="12.25" transform="rotate(-180 13 13)" stroke="black" stroke-width="1.5"/><path d="M16 19L10 13L16 7" stroke="#1F1F1F" stroke-width="1.5"/></svg>'
              class="d-flex">
      <template v-for="course in sameCourses">
        <slide :key="course.id" class="slide-course p-10">
          <same-course-card :course="course" />
        </slide>
      </template>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import {mapGetters} from "vuex";
import SameCourseCard from "./SameCourseCard.vue";
export default {
  name: "SameCourseCarousel",
  components: {
    SameCourseCard,
    Carousel,
    Slide
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['allCourses']),
    sameCourses() {
      return this.allCourses.filter(course => {
        if(course.category) {
          return this.category.id === course.category.id
        }
        return false;
      })
    }
  }
}
</script>

<style>
.slide-course {
  display: flex;
  flex-flow: row wrap;
}
.VueCarousel-navigation-next::before {
  background: url('/img-front/next.svg')!important;
}

.VueCarousel-navigation-prev::before {
  background: url('/img-front/prev.svg')!important;
}

</style>