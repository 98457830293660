<template>
  <div v-if="model" class="fill fill-white">
    <test-question-simple :current-key="currentKey" v-model="model[currentKey]" v-if="question.question.type === 'simple'" :question="question" />
    <test-question-multiple :current-key="currentKey" v-model="model[currentKey]" v-if="question.question.type === 'multiple'" :question="question" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import vmMixin from "../../mixin/vmMixin";
import TestQuestionSimple from "./TestQuestionSimple.vue";
import TestQuestionMultiple from "./TestQuestionMultiple.vue";

export default {
  name: "TestQuestionList",
  components: {TestQuestionMultiple, TestQuestionSimple},
  data() {
    return {
      model: null
    }
  },
  mounted() {
    if(this.questions.questions) {
      this.model = this.questions.questions.map(() => {
        return {}
      })
    }
  },
  mixins: [vmMixin],
  props: {
    currentKey: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  computed: {
    ...mapGetters(['questions', 'test']),
    question() {
      return this.questions.questions[this.currentKey];
    }
  },
  watch: {
    model: {
      handler(val) {

        if(typeof val === "undefined" || !val) {
          return;
        }

        let model = val[this.currentKey]

        let values = []
        if(typeof model === "object") {
          Object.keys(model).forEach(function(key) {
             if(model[key]) {
               values.push(key)
             }
          });
        } else {
          values.push(model)
        }

        if(!values.length) {
          return;
        }

        if(this.questions && this.questions.questions) {
          this.axios.post(`tests/${this.test.id}/pass-question-update`, {
            question: this.questions.questions[this.currentKey].question.id,
            values: values
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>