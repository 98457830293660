<template>
  <div class="d-md-block d-lg-flex justify-content-between align-items-end">
    <div>
      <h1 class="text-rothek font-48 mb-20 mt-20">{{ course.name }}</h1>
      <div class="font-16 line-24" v-html="clearStyle(course.shortDescription)"></div>
    </div>
  </div>
</template>

<script>
import typografMixin from "../../mixin/typografMixin";
import courseMixin from "../../mixin/courseMixin";

export default {
  name: "CourseHeader",
  mixins: [typografMixin, courseMixin]
}
</script>

<style scoped>

</style>