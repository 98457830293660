<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
    <el-form-item prop="name" required>
      <el-input placeholder="Имя" v-model="ruleForm.name"></el-input>
    </el-form-item>
    <el-row :gutter="20">
      <el-col :lg="12" :md="12" :sm="12" :xs="24">
        <el-form-item prop="phone" required>
          <el-input placeholder="Номер телефона" v-model="ruleForm.phone" style="width: 100%;"></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xs="24">
        <el-form-item prop="email" required>
          <el-input placeholder="Email" v-model="ruleForm.email" style="width: 100%;"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="d-flex feedback__form__footer">
      <div class="feedback__form__footer__text">Нажимая на кнопку, я соглашаюсь на <a href="#">обработку персональных данных и с правилами пользования платформой</a></div>
      <button type="button" class="btn btn-black" @click="submitForm('ruleForm')">Отправить</button>
    </div>
  </el-form>
</template>

<script>
export default {
  name: "FeedbackForm",
  data() {
    return {
      ruleForm: {
        name: '',
        phone: '',
        email: ''
      },
      rules: {
        name: [
          { required: true, message: 'Введите Имя', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Введите номер телефона', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Введите email', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>