<template>
  <div class="mt-40 d-flex justify-content-between pr-30 mb-20">
    <button class="btn btn-white" @click="prev()">Назад</button>
    <router-link tag="button" :to="'/course/' + $route.params.id" class="btn btn-white">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1056 3.94721L5.78885 6.60557C5.00831 6.99585 4.61803 7.19098 4.61803 7.5C4.61803 7.80902 5.00831 8.00415 5.78885 8.39443L11.1056 11.0528C11.5445 11.2722 11.7639 11.382 12 11.382C12.2361 11.382 12.4555 11.2722 12.8944 11.0528L18.2111 8.39443C18.9917 8.00415 19.382 7.80902 19.382 7.5C19.382 7.19098 18.9917 6.99585 18.2111 6.60557L12.8944 3.94721C12.4555 3.72776 12.2361 3.61803 12 3.61803C11.7639 3.61803 11.5445 3.72776 11.1056 3.94721Z" fill="#222222"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.02217 10.9893C7.62603 11.3135 8.33716 11.669 9.15554 12.0782L10.2113 12.6061C11.0891 13.045 11.528 13.2644 12.0001 13.2644C12.4723 13.2644 12.9112 13.045 13.789 12.6061L14.8447 12.0782C15.6631 11.669 16.3742 11.3135 16.9781 10.9893L18.2113 11.6059C18.9918 11.9961 19.3821 12.1913 19.3821 12.5003C19.3821 12.8093 18.9918 13.0044 18.2113 13.3947L12.8946 16.0531C12.4557 16.2725 12.2362 16.3822 12.0001 16.3822C11.7641 16.3822 11.5446 16.2725 11.1057 16.0531L11.1057 16.0531L5.78898 13.3947C5.00844 13.0044 4.61816 12.8093 4.61816 12.5003C4.61816 12.1913 5.00844 11.9961 5.78898 11.6059L7.02217 10.9893Z" fill="#222222"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.02169 15.9893C7.62567 16.3135 8.33696 16.6692 9.15557 17.0785L10.2113 17.6063C11.0891 18.0452 11.528 18.2647 12.0001 18.2647C12.4723 18.2647 12.9112 18.0452 13.789 17.6063L14.8447 17.0785C15.6633 16.6692 16.3746 16.3135 16.9786 15.9893L18.2113 16.6056C18.9918 16.9959 19.3821 17.191 19.3821 17.5C19.3821 17.8091 18.9918 18.0042 18.2113 18.3945L12.8946 21.0528C12.4557 21.2723 12.2362 21.382 12.0001 21.382C11.7641 21.382 11.5446 21.2723 11.1057 21.0528L11.1057 21.0528L5.78898 18.3945C5.00844 18.0042 4.61816 17.8091 4.61816 17.5C4.61816 17.191 5.00844 16.9959 5.78898 16.6056L7.02169 15.9893Z" fill="#222222"/>
      </svg>
      К модулям
    </router-link>
    <button class="btn btn-white" @click="next()">Вперед</button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ModulePager",
  methods: {
    next() {
      let path = false
      if(this.module.moduleElements[this.element.position]) {
        path = '/course/' + this.course.id + '/module/' + this.module.position + '/element/' + (this.element.position + 1)
      } else if(this.course.modules[this.module.position]) {
        path = '/course/' + this.course.id + '/module/' + (this.module.position + 1) + '/element/1'
      } else {
        path = '/course/' + this.course.id + '/module/1/element/1'
      }
      if(path) {
        this.$router.push(path)
      }
    },
    prev() {
      let path = false
      if(this.module.moduleElements[this.element.position - 2]) {
        path = '/course/' + this.course.id + '/module/' + this.module.position + '/element/' + this.module.moduleElements[this.element.position - 2].position
      } else if(this.course.modules[this.module.position - 2]) {
        path = '/course/' + this.course.id + '/module/' + this.course.modules[this.module.position - 2].position + '/element/' + this.course.modules[this.module.position - 2].moduleElements.length
      } else {
        path = '/course/' + this.course.id + '/module/' + this.course.modules.length +'/element/' + this.course.modules[this.course.modules.length - 1].moduleElements.length
      }
      if(path) {
        this.$router.push(path)
      }
    }
  },
  computed: {
    ...mapGetters(['module', 'element', 'course'])
  }
}
</script>

<style scoped>

</style>