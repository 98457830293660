export default {
    data() {
      return {
          windowWidth: null,
          lg: this.withFilter ? 12 : 8
      }
    },
    methods: {
        size() {
            this.windowWidth = document.documentElement.clientWidth
            let size = 3;
            if(this.windowWidth < 768) {
                size = 1
            } else if(this.windowWidth < 1260) {
                size = 2
            }
            return this.withFilter ? size - 1 : size;
        },
    },
    props: {
        withFilter: {
            type: Boolean,
            default() {
                return false
            }
        }
    }
}