<template>
  <div class="test__info" v-if="test.attempts && test.attempts.length">
    <div class="row test">
      <div class="col-md-6 col-sm-6 fill p-30 pr-28 pl-28" :class="{'fill-green': isSuccess, 'fill-danger': !isSuccess}" style="position: relative;">
        <div class="d-flex flex-column justify-space-between">
          <div class="text-rothek font-25">
            {{ isSuccess ? 'Вы прошли тест' : 'Вы не прошли тест' }}!
          </div>
          <div class="text-rothek font-25 mt-10">
            Процент верных ответов:
          </div>
          <div class="d-flex" style="position: absolute; bottom: 30px;">
            <div class="text-rothek font-100 color-dark">
              {{ test.result.percentResult }}
            </div>
            <div class="text-rothek font-80 color-dark-mute align-self-end">
              %
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12 col-sm-12 fill fill-purple mb-15 p-30 pr-28 pl-28">
          <div class="d-flex justify-content-between">
            <div class="text-rothek font-25">
              Количество<br/>вопросов
            </div>
            <div class="text-rothek font-100 color-dark" style="align-self: last baseline;">
              {{ test.questionsCount }}
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12  fill fill-orange p-30 pr-28 pl-28">
          <div class="d-flex flex-column justify-content-between">
            <div class="text-rothek font-25">
              Завершен
            </div>
            <div class="text-rothek color-dark mt-30 align-self-end" style="text-align: right">
              <small class="font-40" style="opacity: 0.6">{{ test.attempts[0].finishedAt | formatDate('DD.MM.YYYY') }}</small><br/>
              <span class="font-100">{{ test.attempts[0].finishedAt | formatDate('HH:mm') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20 d-flex justify-content-between align-items-center" v-if="test.elapsedAttemptsCount">
      <div class="text-dark text-rothek">Осталось попыток попыток: <span>{{ test.elapsedAttemptsCount }}</span></div>
      <button @click.prevent="start()" class="btn btn-sm btn-black">Пройти тест еще раз</button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {eventBus} from "../../main";

export default {
  name: "TestResult",
  computed: {
    ...mapGetters(['test']),
    isSuccess() {
      return this.test.result.percentResult > 51;
    }
  },
  methods: {
    start() {
      eventBus.$emit('start:test')
    }
  }
}
</script>

<style scoped>

</style>