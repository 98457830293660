<template>
  <div v-if="test">
    <test-progress v-if="test.testInProcess && questions" />
    <template v-else-if="test.result">
      <test-result />
    </template>
    <template v-else>
      <test-start-info :test="test" />
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TestStartInfo from "../test/TestStartInfo.vue";
import TestProgress from "../test/TestProgress.vue";
import TestResult from "../test/TestResult.vue";

export default {
  name: "BaseTest",
  components: {TestResult, TestProgress, TestStartInfo},
  async mounted() {
    await this.getTest(this.testId)
    if(this.test && this.test.testInProcess) {
      await this.getQuestions(this.testId)
    }
  },
  computed: {
    ...mapGetters(['element', 'test', 'questions']),
    testId() {
      return this.element.test.id
    }
  },
  methods: {
    ...mapActions(['getTest', 'getQuestions'])
  }
}
</script>

<style scoped>

</style>