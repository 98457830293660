<template>
  <div>
    <div class="md-hidden">
      <div class="fill mt-40 feedback" :class="'bg-' + fill">
        <div class="feedback__text">
          <h3 class="text-rothek mb-20">Остались вопросы?</h3>
          <p>
            Оставьте ваши данные и наш специалист<br/> с вами свяжется, чтобы помочь
          </p>
        </div>
        <div class="feedback__form">
          <feedback-form />
        </div>
      </div>
    </div>
    <div class="md-visible">
      <div class="fill fill-grey mt-20 text-center">
        <h1 class="text-rothek mb-20">Остались вопросы?</h1>
        <p class="mb-20">
          Оставьте ваши данные и наш специалист<br/> с вами свяжется, чтобы помочь
        </p>
        <feedback-form />
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackForm from "./FeedbackForm.vue";

export default {
  name: "Feedback",
  components: {FeedbackForm},
  props: {
    fill: {
      type: String,
      default: 'blue'
    }
  }
}
</script>