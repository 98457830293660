<template>
  <div class="fill fill-sm fill-grey text-rothek p-10" style="text-wrap: nowrap;">{{ currentKey + 1 }} / {{ questions.questions.length }}</div>
</template>

<script>
import {mapGetters} from "vuex";
import testQuestionMixin from "../../mixin/testQuestionMixin";

export default {
  name: "TestQuestionCount",
  mixins: [testQuestionMixin],
  computed: {
    ...mapGetters(['questions'])
  }
}
</script>

<style scoped>

</style>