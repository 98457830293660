const VIDEO_TYPE = 'video';
const MODULE_TYPE = 'module';
const COURSE_ENROLL_TYPE = 'enroll';
const COURSE_UN_ENROLL_TYPE = 'unenroll';
const ENTER_TYPE = 'enter';

const TYPES = {
    VIDEO_TYPE: VIDEO_TYPE, MODULE_TYPE: MODULE_TYPE, COURSE_ENROLL_TYPE: COURSE_ENROLL_TYPE, COURSE_UN_ENROLL_TYPE: COURSE_UN_ENROLL_TYPE, ENTER_TYPE: ENTER_TYPE
}

let TYPES_LIST = {};
TYPES_LIST[VIDEO_TYPE] = 'Просмотр видеолекции'
TYPES_LIST[MODULE_TYPE] = 'Изучение модуля'
TYPES_LIST[COURSE_ENROLL_TYPE] = 'Запись на курс'
TYPES_LIST[COURSE_UN_ENROLL_TYPE] = 'Отписка от курса'
TYPES_LIST[ENTER_TYPE] = 'Вход в систему'

export {TYPES, TYPES_LIST};

export default TYPES;
