import TYPES, {TYPES_LIST} from "../../../src/components/User/actionsTypes";

export default {
    methods: {
        addAction(type, title = null, link = this.$router.currentRoute.path) {

            if(type === TYPES.ENTER_TYPE) {
                link = null;
            }

            if(!title) {
                title = TYPES_LIST[type];
            }

            if(this.$auth.check() || type === TYPES.ENTER_TYPE) {
                this.axios.post('user_activity_logs', {
                    link: link,
                    title: title,
                    type: type
                })
            }
        }
    }
}