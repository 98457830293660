<template>
  <div class="d-flex justify-content-between align-items-start">
    <div class="text-dark text-rothek font-24" v-html="question.question.name"></div>
    <test-question-count :current-key="currentKey" />
  </div>
</template>

<script>
import TestQuestionCount from "./TestQuestionCount.vue";
import testQuestionMixin from "../../mixin/testQuestionMixin";

export default {
  name: "TestQuestionName",
  components: {TestQuestionCount},
  mixins: [testQuestionMixin],
  props: {
    question: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>