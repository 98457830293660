<template>
  <div class="header">
    <router-link to="/" class="pt-20">
      <img alt="Uni logo" src="../assets/logo.svg">
    </router-link>
    <main-menu />
    <mobile-menu />
  </div>
</template>

<script>
import MainMenu from "./MainMenu.vue";
import MobileMenu from "./MobileMenu.vue";

export default {
  name: "TplHeader",
  components: {MobileMenu, MainMenu}
}
</script>

<style scoped>
</style>